import React, { useContext, useEffect, useMemo, useState } from 'react';
import GeneralState from '../misc/context/GeneralState';
import { MainContext, MainContextType } from '../misc/context/MainContext';
import CurrentUser from '../model/CurrentUser';
import './utils.scss';
import './style.scss';
import AppRoutes from './AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, Row, Spin } from 'antd';
import Helmet from 'react-helmet';
import ApiClient from 'api/base/ApiClient';
import { AppSettings } from 'appSettings';
import Cookies from 'js-cookie';
import VSHubClient from 'misc/vshub/vshub';
import { COLORS } from 'vs-recording/styles/Colors';
import { ThemeConfig } from 'antd/lib';


const apiClient = new ApiClient({
	baseUrl: AppSettings.api.url,
	auth: {
		access: {
			name: AppSettings.auth.access.name,
			durationDays: AppSettings.auth.access.durationDays,
		},
		refresh: {
			name: AppSettings.auth.refresh.name,
			durationDays: AppSettings.auth.refresh.durationDays,
		}
	},
	setCookies: (key: string, value: string, params: any) =>
		new Promise((res) => { Cookies.set(key, value, params); res() }),

	getCookies: (key: string) =>
		new Promise((res) => res(Cookies.get(key))),

	deleteCookies: (key: string) =>
		new Promise((res) => res(Cookies.remove(key))),
});

const currentUser = new CurrentUser(apiClient);

const mainContextValue = {
	generalState: new GeneralState(),
	api: apiClient,
	currentUser: currentUser,
	vsHub: new VSHubClient({ hubUrl: AppSettings.hub.url, user: currentUser })
}


const App: React.FC = () => {
	return <MainContext.Provider value={mainContextValue}>
		<Helmet>
			<title>VetSkribe</title>
			<meta name="description" />
		</Helmet>
		<AppContent />
	</MainContext.Provider>
}

const AppContent: React.FC = () => {
	const ctx = useContext(MainContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (ctx.currentUser.accessToken == null && ctx.currentUser.refreshToken == null) {
			setLoading(false);
			return;
		}
		initCurrentUser(ctx.api);
	}, []);


	const initCurrentUser = async (apiClient: ApiClient) => {
		try {
			await ctx.currentUser.init(apiClient);
			setLoading(false);
			await ctx.vsHub.init();
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	const theme = useMemo(() => ({
		token: {
			colorPrimary: COLORS.buttonBg,
			colorSuccess: COLORS.green,
			colorError: COLORS.red,
			colorInfo: COLORS.textLink,
			colorTextBase: COLORS.black,
			colorBgBase: COLORS.mainBG,
			colorLink: COLORS.textLink,
			borderRadius: 8,
			fontSize: 14,
		},
		components: {
			Button: {
				borderRadius: '100px !important' as any,
			},
			Progress: {
				circleIconFontSize: "12px",
			},
			Modal: {
				colorPrimary: COLORS.buttonBg,
                colorPrimaryHover: COLORS.buttonBgHover,
                colorPrimaryActive: COLORS.buttonBg,
			}
		}
	} as ThemeConfig), []);

	if (loading)
		return <div className='layout'>
			<Row align='middle' justify='center' style={{ height: "100%" }}>
				<Spin delay={500} />
			</Row>
		</div>

	return <ConfigProvider theme={theme}>
		<BrowserRouter>
			<AppRoutes />
		</BrowserRouter>
	</ConfigProvider>;
}


export default App;
