// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edPDsrsKdftlVnCI9NsK{width:100%}.edPDsrsKdftlVnCI9NsK .MLGS19azHfWEPx2hqNF4{display:flex;margin-bottom:6px}.edPDsrsKdftlVnCI9NsK .MLGS19azHfWEPx2hqNF4:last-child{margin-bottom:0}.edPDsrsKdftlVnCI9NsK .MLGS19azHfWEPx2hqNF4 .s7RQLwMMEDsNwqObdsye{font-weight:bold;margin-right:5px}.edPDsrsKdftlVnCI9NsK .MLGS19azHfWEPx2hqNF4 .Rf9a50mF9RxMVOXSl9Mg{flex:1;white-space:pre-line}.edPDsrsKdftlVnCI9NsK.T_02OaxcWn_TvDBeRtyY .MLGS19azHfWEPx2hqNF4{flex-direction:row}.edPDsrsKdftlVnCI9NsK._BZJYzbpKdEdCwg0a_3Q .MLGS19azHfWEPx2hqNF4{flex-direction:column}.edPDsrsKdftlVnCI9NsK .mykC_J3e07VQ6Y6gC7zF{font-size:18px;font-weight:bold;margin-top:15px}", "",{"version":3,"sources":["webpack://./src/app/components/Details/Details.module.scss"],"names":[],"mappings":"AACA,sBACI,UAAA,CAEA,4CACI,YAAA,CACA,iBAAA,CAEA,uDACI,eAAA,CAGJ,kEACI,gBAAA,CACA,gBAAA,CAGJ,kEACI,MAAA,CACA,oBAAA,CAKJ,iEACI,kBAAA,CAKJ,iEACI,qBAAA,CAIR,4CACI,cAAA,CACA,gBAAA,CACA,eAAA","sourcesContent":["@import \"app/colors.scss\";\n.details {\r\n    width: 100%;\r\n\r\n    .detailsItem {\r\n        display: flex;\r\n        margin-bottom: 6px;\r\n\r\n        &:last-child {\r\n            margin-bottom: 0;\r\n        }\r\n\r\n        .detailsTitle {\r\n            font-weight: bold;\r\n            margin-right: 5px;\r\n        }\r\n\r\n        .detailsValue {\r\n            flex: 1;\r\n            white-space: pre-line;\r\n        }\r\n    }\r\n\r\n    &.detailsH {\r\n        .detailsItem {\r\n            flex-direction: row;\r\n        }\r\n    }\r\n\r\n    &.detailsV {\r\n        .detailsItem {\r\n            flex-direction: column;\r\n        }\r\n    }\r\n\r\n    .separator {\r\n        font-size: 18px;\r\n        font-weight: bold;\r\n        margin-top: 15px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"details": "edPDsrsKdftlVnCI9NsK",
	"detailsItem": "MLGS19azHfWEPx2hqNF4",
	"detailsTitle": "s7RQLwMMEDsNwqObdsye",
	"detailsValue": "Rf9a50mF9RxMVOXSl9Mg",
	"detailsH": "T_02OaxcWn_TvDBeRtyY",
	"detailsV": "_BZJYzbpKdEdCwg0a_3Q",
	"separator": "mykC_J3e07VQ6Y6gC7zF"
};
export default ___CSS_LOADER_EXPORT___;
