import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./AudioVisualizer.module.scss";

type AudioVisualizerProps = {
    active: boolean;
    stream?: MediaStream | null;
};

const AudioVisualizer: React.FC<AudioVisualizerProps> = ({ active, stream }) => {
    const barWidth = 3; // Ширина полос
    const barSpacing = 2; // Расстояние между полосами
    const barHeightMultiplier = 220; // Коэффициент высоты полос
    const totalBars = Math.floor(window.innerWidth / (barWidth + barSpacing)); // Общее количество полос
    const [waveform, setWaveform] = useState<number[]>(new Array(totalBars).fill(0)); // Инициализация массива полос
    const audioContextRef = useRef<AudioContext | null>(null);
    const analyserRef = useRef<AnalyserNode | null>(null);
    const dataArrayRef = useRef<Uint8Array | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null); // Используем для контроля скорости
    const recorderContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (active && stream) {
            initAudioProcessing(stream);
        } else {
            stopAudioProcessing();
        }

        return () => stopAudioProcessing();
    }, [active, stream]);

    const initAudioProcessing = (stream: MediaStream) => {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();

        analyser.fftSize = 64; // Оптимальный размер для быстрой реакции
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        dataArrayRef.current = dataArray;

        audioContextRef.current = audioContext;
        analyserRef.current = analyser;

        startVisualizing();
    };

    const startVisualizing = () => {
        intervalRef.current = setInterval(() => {
            if (!analyserRef.current || !dataArrayRef.current) return;

            analyserRef.current.getByteTimeDomainData(dataArrayRef.current); // Получаем данные амплитуды

            let maxAmplitude = 0;
            for (let i = 0; i < dataArrayRef.current.length; i++) {
                maxAmplitude = Math.max(maxAmplitude, dataArrayRef.current[i]);
            }

            const normalizedVolume = maxAmplitude / 128 - 1; // Нормализуем громкость

            setWaveform((prev) => {
                // Добавляем новую высоту в массив, смещая остальные влево
                const newWaveform = [Math.abs(normalizedVolume), ...prev.slice(0, totalBars - 1)];
                return newWaveform;
            });
        }, 50); // Постоянная скорость обновления (20 кадров в секунду)
    };

    const stopAudioProcessing = () => {
        if (audioContextRef.current) {
            audioContextRef.current.close();
            audioContextRef.current = null;
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    const renderWaveform = () => {
        const centerX = Math.floor(recorderContainerRef?.current?.offsetWidth / 2.01); // Позиция центральной линии
        return waveform.map((height, index) => {
            const x = centerX - index * (barWidth + barSpacing); // Полосы двигаются влево от центра
            if (x < 0) return null;

            const barHeight = height * barHeightMultiplier; // Высота полосы зависит от громкости

            return <rect
                key={index}
                x={x}
                y={(150 - barHeight) / 2} // Центрируем по вертикали
                width={barWidth}
                height={barHeight}
                className={styles.bar}
            />;
        });
    };

    const renderCenterLine = useCallback(() => {
        const centerX = Math.floor(recorderContainerRef?.current?.offsetWidth / 2); // Центр экрана
        return (
            <line
                x1={centerX}
                y1="0"
                x2={centerX}
                y2="150"
                className={styles.centerLine}
            />
        );
    }, [recorderContainerRef?.current?.offsetWidth]);

    const renderHorizontalLine = useCallback(() => {
        return (
            <line
                x1="0"
                y1="75" // Центр по вертикали
                x2="100%"
                y2="75"
                className={styles.horizontalLine}
            />
        );
    }, []);

    return (
        <div className={styles.container} ref={recorderContainerRef}>
            <svg width="100%" height={150}>
                {renderHorizontalLine()}
                {renderCenterLine()}
                {renderWaveform()}
            </svg>
        </div>
    );
};

export default AudioVisualizer;
