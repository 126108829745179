// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OScSO9nKxii1ovS6PyWt{padding:4px}", "",{"version":3,"sources":["webpack://./src/vs-recording/components/Recording/components/MicActivityIndicator/MicActivityIndicator.module.scss"],"names":[],"mappings":"AACA,sBACI,WAAA","sourcesContent":["@import \"app/colors.scss\";\n.micActivityIndicator {\r\n    padding: 4px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"micActivityIndicator": "OScSO9nKxii1ovS6PyWt"
};
export default ___CSS_LOADER_EXPORT___;
