import { CopyOutlined, DeleteOutlined, EditOutlined, ReloadOutlined, ExportOutlined } from "@ant-design/icons";
import { Alert, Breadcrumb, Button, Divider, Drawer, Input, Modal, notification, Popconfirm, Space, Table, TablePaginationConfig, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { Template } from "api/model/Template/Template";
import PracticeSelect from "app/components/PracticeSelect";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import useDrawer from "misc/hooks/useDrawer";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TemplateEdit from "./TemplateEdit";
import { TemplateStore } from "./TemplateStore";
import TextArea from "antd/es/input/TextArea";
import AccountSelect from "app/components/AccountSelect";


const TemplateList: React.FC = observer(() => {
	const api = useApi();
	const store = useLocalObservable<TemplateStore>(() => new TemplateStore(api));
	const user = useCurrentUser();
	const templateDrawer = useDrawer<Template>();
	const exporttDrawer = useDrawer<Template>();
	const [exportTemplateData, setExportTemplateData] = useState<Template | null>(null);

	const importDrawer = useDrawer<Template>();
	const [importTemplateData, setImportTemplateData] = useState<string | null>(null);

	useEffect(() => {
		store.notificationApi = notification;
	}, [])

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<Template> | SorterResult<Template>[] | any) => {
		store.setSortingPaging(sorter.columnKey, sorter.order, pagination.current, pagination.pageSize);
	};

	let columns: ColumnsType<Template> = [
		{
			title: 'Name',
			key: 'Name',
			ellipsis: true,
			sorter: true,
			render: (x: Template) => <>
				<a onClick={() => templateDrawer.open(x)}>
					{x.name}
				</a>
				{x.description && <div className="secondary-text">{x.description}</div>}
			</>
		},
		{
			title: 'Created Date',
			key: 'CreatedDate',
			sorter: true,
			defaultSortOrder: "descend",
			render: (x: Template) =>
				<>
					{x.createdDate ?
						<span>{moment(x.createdDate).format("DD MMM yyyy HH:mm:ss")}</span>
						:
						<span className="secondary-text">No data</span>}

					{x.createdBy &&
						<div className="secondary-text">
							by {x.createdBy.firstName} {x.createdBy.lastName}
						</div>
					}
				</>
		},
		{
			title: 'Published',
			key: 'isPublished',
			render: (x: Template) =>
				<>
					{x.isPublished ?
						<Tag color="green">Published </Tag>
						:
						<Tag >Not Published</Tag>
					}
				</>
		},
		{
			title: 'Account',
			key: 'Account',
			render: (x: Template) =>
				<>
					{x.account ?
						<span>{x.account?.name}</span>
						:
						<span className="secondary-text">For all accounts</span>}
				</>
		},
		{
			title: 'Hospital',
			key: 'Practice',
			render: (x: Template) =>
				<>
					{x.practice ?
						<span>{x.practice.name}</span>
						:
						<span className="secondary-text">For all hospitals</span>}
				</>
		},
		{
			title: 'Doctors',
			key: 'Doctors',
			render: (x: Template) =>
				<>
					{x.doctors.length > 0 ?
						<>
							{x.doctors.map(x => <Tag key={x.id}>{x.firstName} {x.lastName}</Tag>)}
						</>
						:
						<span className="secondary-text">For all doctors</span>}
				</>
		},
		{
			title: 'Actions',
			align: "right",
			render: (x: Template) => <Space key={x.id}>
				{x.softDeleted && <Tag color={"red"}>Removed</Tag>}

				<Button icon={<EditOutlined />} onClick={() => templateDrawer.open(x)} />
				<Button icon={<CopyOutlined />} onClick={() => store.duplicate(x)} />

				{user.isSuperAdmin &&
					<Button icon={<ExportOutlined />} onClick={() => {
						api.adminTemplates.export(x).then(x => {
							setExportTemplateData(x);
							exporttDrawer.open();
						});
					}} />
				}

				{!x.softDeleted ?
					<Popconfirm title="Are you sure to delete?" onConfirm={() => store.delete(x)}>
						<Button danger icon={<DeleteOutlined />} />
					</Popconfirm>
					: <Button icon={<ReloadOutlined />} onClick={() => store.restore(x)} />
				}
			</Space>
		},
	];

	if (user.isSAASUser) {
		var onlySuperAdminCols = ["Account"];
		columns = columns.filter(x => !onlySuperAdminCols.includes(x.key as string))
	}

	return <div className="vs_table_wrapper">
		<Space style={{ marginBottom: 10 }} wrap={false}>
			<Input.Search
				style={{ minWidth: 200 }}
				placeholder="Search"
				defaultValue={store.filter.search || ""}
				onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => store.setSearch(e.currentTarget.value)}
			/>

			<PracticeSelect value={store.filter.idPractice} onChange={x => store.setFilter({ idPractice: x })}></PracticeSelect>

			{user.isSuperAdmin &&
				<AccountSelect value={store.filter.idAccount} onChange={x => store.setFilter({ idAccount: x })}></AccountSelect>
			}

			<Button type='primary' onClick={() => templateDrawer.open({ id: 0, fields: [], idsDoctors: [] })}>Add new template</Button>


			{user.isSuperAdmin &&
				<Button onClick={() => {
					setImportTemplateData(null);
					importDrawer.open();
				}} >Import</Button>
			}
		</Space>

		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
				<Table
					onChange={handleTableChange}
					loading={store.loading}
					rowKey={x => x.id ? x.id : ""}
					columns={columns}
					tableLayout='auto'
					dataSource={store.pagedData?.data}
					pagination={{
						hideOnSinglePage: true,
						current: store.pagedData?.page,
						total: store.pagedData?.total,
						pageSize: store.pagedData?.pageSize,
						showSizeChanger: true,
						showTotal: (total: number) => `Total: ${total}`
					}}
				/>
		}

		<Drawer open={templateDrawer.visible}
			width={650}
			destroyOnClose
			closable
			onClose={() => templateDrawer.close()}
			title={"Template Editor"}>
			<TemplateEdit template={templateDrawer.data}
				onCancel={() => templateDrawer.close()}
				onSuccess={() => {
					templateDrawer.close();
					store.load();
				}} />
		</Drawer>

		<Modal open={exporttDrawer.visible} onCancel={exporttDrawer.close} onOk={exporttDrawer.close}>
			<TextArea value={JSON.stringify(exportTemplateData || "", null, 4)} rows={30}></TextArea>
		</Modal>


		<Modal open={importDrawer.visible}
			onCancel={importDrawer.close}
			footer={() => <>
				<Button onClick={e => {
					var data = JSON.parse(importTemplateData || "");
					api.adminTemplates.import(data).then(x => {
						importDrawer.close();
						store.load();
					});
				}}>Import</Button>
			</>}>
			<TextArea
				placeholder="This is developer function. Paste JSON of the template here"
				rows={30}
				value={importTemplateData}
				onChange={e => setImportTemplateData(e.currentTarget.value)}
			/>

		</Modal>
	</div>
})


export default TemplateList;




