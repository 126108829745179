import useCurrentUser from "misc/hooks/useCurrentUser";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from './AdminProfile.module.scss';

const AdminProfile: React.FC = () => {
    const user = useCurrentUser();
    const nav = useNavigate();

    return <div className="flex flex-col align-center gap-5">
        <span className={styles.name}>{user.firstName} {user.lastName!}</span>
        <div className={styles.logoutBtn} onClick={() => nav('/auth/logout')}>
            Logout
        </div>
    </div>;
}

export default AdminProfile;