import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Button, Image } from "antd";
import { AppointmentStoreContext } from "vs-recording/stores/appointment/AppointmentStoreContext";
import { AppointmentFeedbackClientDto } from "api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto";
import styles from "./SubmitFeedback/SubmitFeedback.module.scss";
import processingLogo from "assets/v2/mini-logo.svg";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";
import { AudioOutlined, ReloadOutlined } from "@ant-design/icons";
import FadeIn from "app/components/FadeIn/FadeIn";

type ProcessingProps = {
    feedback: AppointmentFeedbackClientDto;
};

const Processing: React.FC<ProcessingProps> = observer(({ feedback }) => {
    const appointmentContext = useContext(AppointmentStoreContext);
    const nav = useNavigate();

    const springProps = useSpring({
        from: { transform: "scale(1)" },
        to: { transform: "scale(1.1)" },
        config: { duration: 1000 },
        loop: { reverse: true },
    });

    return <FadeIn>
        <div className="flex flex-col h-100">
            <div className="mt-20 mb-20 text-center flex-1 flex flex-col justify-center">
                <animated.div style={springProps}>
                    <Image preview={false} src={processingLogo} style={{ width: 180 }} />
                </animated.div>
                <div className="flex flex-col fs-16 color-grey mt-20">
                    <p className="fs-20">Recording is being processed</p>
                    <p className="mt-10">Please wait.</p>
                    <p className="mt-5">Use the refresh button to check again</p>
                </div>
            </div>
            <div className={styles.footer}>
                <Button size="large" onClick={() => nav("/rec/schedule")}>
                    <AudioOutlined />
                    Go to Recordings
                </Button>
                <Button
                    size="large"
                    onClick={async () => await appointmentContext!.refresh()}
                    loading={appointmentContext?.loading === true}
                    disabled={feedback?.isEditing}>
                    <ReloadOutlined />
                    Refresh
                </Button>
            </div>
        </div>
    </FadeIn>;
});

export default Processing;
