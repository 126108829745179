import { ArrowLeftOutlined, EditOutlined, UploadOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, notification } from 'antd';
import { AppointmentFeedbackStatus } from 'api/vs-recording/model/Appointments/enums/AppointmentFeedbackStatus';
import { AppointmentFeedbackClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto';
import FeedbackOutput from 'app/components/FeedbackOutputView/FeedbackOutput';
import useCurrentUser from 'misc/hooks/useCurrentUser';
import useDrawer from 'misc/hooks/useDrawer';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect } from 'react';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import { COLORS } from 'vs-recording/styles/Colors';
import styles from './SubmitFeedback/SubmitFeedback.module.scss';
import Summary from './Summary/Summary';
import FadeIn from 'app/components/FadeIn/FadeIn';
import useApi from 'misc/hooks/useApi';


type SubmittedProps = {
    feedback: AppointmentFeedbackClientDto;
    onEdit: () => void;
    onBack: () => void;
    onResubmit: () => void;
};


export const Submitted: React.FC<SubmittedProps> = observer(({ feedback, onEdit, onBack, onResubmit }) => {
    const appointmentContext = useContext(AppointmentStoreContext);
    const user = useCurrentUser();
    const outputModal = useDrawer<number>();
    const api = useApi();

    const approveFeedback = () => {
        appointmentContext?.feedbackAllChecked(feedback);
    };


    const startEditFeedback = async () => {
        try {
            await appointmentContext?.feedbackStartEdit(feedback);
            onEdit();
        } catch (e: any) {
            notification.error({ message: e.message, duration: 2 });
        }
    };

    const goBackToReSubmit = async () => {
        Modal.confirm({
            title: 'Want to re-submit?',
            content: `All your recordings will be preserved. You will be able to delete/make new recordings. All typed text will be lost if using Ambient Mode. If you want to make some text edits, press "Edit" instead.`,
            onOk: async () => {
                try {
                    await appointmentContext!.goBackToReSubmit();
                    onResubmit();
                } catch (e: any) {
                    notification.error({ message: e.message, duration: 2 });
                    await appointmentContext!.refresh();
                }
            },
        });
    };

    return <>
        <FadeIn>
            <div>
                <Summary feedback={feedback} />

                <footer className={styles.footer}>
                    <div className='flex align-center gap-5'>
                        {feedback.status != AppointmentFeedbackStatus.Completed && <>
                            <Button
                                size='large'
                                onClick={goBackToReSubmit}
                                disabled={feedback.isEditing}>
                                <ArrowLeftOutlined />
                                {feedback?.isEditing ? 'Taken by an Editor' : 'Back to draft'}
                            </Button>
                        </>}
                        <Button
                            size='large'
                            onClick={startEditFeedback}
                            loading={appointmentContext?.loading}>
                            <EditOutlined />
                            Edit
                        </Button>

                    </div>

                    <div className='flex align-center gap-5'>
                        <Button
                            size='large'
                            onClick={() => outputModal.open(feedback?.id)}>
                            <UploadOutlined />
                            Export note
                        </Button>
                        {(user.isSAASUser || user.isSuperAdmin) && <Button size='large'
                            loading={appointmentContext?.loading}
                            style={{
                                background: feedback?.addedToPims ? COLORS.green : COLORS.buttonBg,
                                border: feedback?.addedToPims ? COLORS.green : COLORS.buttonBg
                            }}>
                            <Popconfirm title={feedback?.addedToPims ? 'Remove from PIMS?' : 'Add to PIMS?'}
                                onConfirm={() => appointmentContext.addToPIMS(feedback.addedToPims)}>
                                {feedback?.addedToPims ? 'Added to PIMS' : 'Add to PIMS'}
                            </Popconfirm>
                        </Button>}
                        {feedback?.status === AppointmentFeedbackStatus.Completed
                            ? <Button
                                size='large'
                                disabled>
                                {user.isSAASUser ? 'Completed' : 'Approved'}
                            </Button>
                            : <Button
                                size='large' onClick={approveFeedback}>
                                {user.isSAASUser ? 'Complete' : 'Approve'}
                            </Button>}
                    </div>
                </footer>
            </div>
        </FadeIn>

        <Modal open={outputModal.visible}
            width={850}
            onOk={() => outputModal.close()}
            onCancel={() => outputModal.close()}
            title={"Output"}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            destroyOnClose={true}
            closable={true}
            maskClosable={false}>
            {<FeedbackOutput id={outputModal.data!} />}
        </Modal>
    </>;
});

export default Submitted;
