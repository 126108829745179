import { UndoOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Input, List, Skeleton, Tabs, ThemeConfig } from "antd";
import { AppointmentFeedbackStatus } from "api/vs-recording/model/Appointments/enums/AppointmentFeedbackStatus";
import { AppointmentClientDto } from "api/vs-recording/routes/Appointment/DTO/AppointmentClientDto";
import { AppointmentListRequest } from "api/vs-recording/routes/Appointment/Model/AppointmentListRequest";
import MyDatePicker from "app/components/DatePicker";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OneAppointment } from "vs-recording/components/Schedule/AppointmentList/AppointmentList";
import { AppointmentListStore } from "vs-recording/components/Schedule/AppointmentList/AppointmentListStore";
import { COLORS } from "vs-recording/styles/Colors";
import styles from "./RecList.module.scss";
import FadeIn from "app/components/FadeIn/FadeIn";


type Tabs = 'draft' | 'submitted' | 'approved' | 'exported';

const TAB_STORAGE_KEY = "VS_recList_activeTab";

const RecList: React.FC = () => {
    const [activeTab, setActiveTab] = useState<Tabs | null>(() => {
        const savedTab = localStorage.getItem(TAB_STORAGE_KEY);
        return (savedTab as Tabs) || 'draft';
    });
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const width = window.innerWidth;

    const wrapperRef = useRef<HTMLDivElement>(null);
    const theme = useMemo(() => ({
        components: {
            Typography: {
                colorTextDisabled: COLORS.disabledBG
            },
            Button: {
                defaultBg: COLORS.buttonBg,
                colorText: COLORS.white,
                colorBorder: COLORS.buttonBg,
                defaultHoverBorderColor: COLORS.buttonBgHover,
                defaultHoverBg: COLORS.buttonBgHover,
                defaultHoverColor: COLORS.white,
                colorTextDisabled: COLORS.grey,
                borderColorDisabled: COLORS.lightGrey,
                controlItemBgActiveDisabled: COLORS.lightGrey,
                colorLink: COLORS.orange,
                colorLinkHover: COLORS.buttonBgHover,
                borderRadius: '100px !important' as any

            },
            Radio: {
                buttonSolidCheckedBg: COLORS.buttonBg,
                buttonSolidCheckedHoverBg: COLORS.buttonBgHover,
                buttonSolidCheckedColor: COLORS.white,
                buttonBg: COLORS.white,
                buttonColor: COLORS.grey,
                colorBorder: COLORS.grey,
                colorPrimaryActive: COLORS.buttonBg,
                colorInfoTextHover: COLORS.grey,
                colorPrimary: COLORS.grey,
                colorTextDisabled: COLORS.lightGrey,
                buttonCheckedColorDisabled: COLORS.lightGrey,
                buttonCheckedBgDisabled: COLORS.grey,
            },
            Select: {
                colorTextDisabled: COLORS.lightGrey,
            },
            Progress: {
                circleIconFontSize: "12px",
                defaultColor: COLORS.buttonBg,
                colorSuccess: COLORS.buttonBg
            },
            Modal: {
                colorPrimary: COLORS.buttonBg,
                colorPrimaryHover: COLORS.buttonBgHover,
                colorPrimaryActive: COLORS.buttonBg,
            },
            Tabs: {
                itemColor: COLORS.black,
                itemActiveColor: COLORS.orange,
                itemSelectedColor: COLORS.orange,
                inkBarColor: COLORS.orange,
                itemHoverColor: COLORS.black,
                titleFontSize: width < 840 ? '16px' : '22px' as any
            },
            Input: {
                borderRadius: 30
            },
            DatePicker: {
                borderRadius: 30,
                colorPrimary: COLORS.orange,
            }
        }
    } as ThemeConfig), []);


    const goToDraft = useCallback((id: number) => {
        navigate(`/rec/appointment/${id}`);
    }, []);

    const goToResults = useCallback((id: number) => {
        navigate(`/recList/appointment/${id}`);
    }, []);

    useEffect(() => {
        if (activeTab) {
            localStorage.setItem(TAB_STORAGE_KEY, activeTab);
        }
    }, [activeTab]);

    return <ConfigProvider theme={theme} getPopupContainer={() => wrapperRef.current}>
        <div className={styles.wrapper} ref={wrapperRef} >
            <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key as Tabs)} centered tabBarGutter={width < 840 ? 40 : 100}>
                <Tabs.TabPane tab="Drafts" key="draft">
                    {activeTab === 'draft' &&
                        <AppointmentListView filter={{ feedbackStatus: AppointmentFeedbackStatus.Draft }}
                            onAppointmentClick={x => goToDraft(x.id)} />}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Submitted" key="submitted">
                    {activeTab === 'submitted' &&
                        <AppointmentListView filter={{ feedbackStatus: AppointmentFeedbackStatus.Markup }}
                            onAppointmentClick={(x) => goToResults(x.id)} />}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Approved" key="approved">
                    {activeTab === 'approved' &&
                        <AppointmentListView filter={{ feedbackStatus: AppointmentFeedbackStatus.Completed }}
                            onAppointmentClick={(x) => goToResults(x.id)} />}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Added to PIMS" key="exported">
                    {activeTab === 'exported' &&
                        <AppointmentListView filter={{ feedbackStatus: AppointmentFeedbackStatus.Completed, feedbackExported: true }}
                            onAppointmentClick={(x) => goToResults(x.id)} />}
                </Tabs.TabPane>
            </Tabs>
        </div>
    </ConfigProvider>;
}

export default RecList;


type AppointmentListViewProps = {
    filter: Partial<AppointmentListRequest>;
    onAppointmentClick: (appointment: AppointmentClientDto) => void;
}

const AppointmentListView: React.FC<AppointmentListViewProps> = observer(({ filter, onAppointmentClick }) => {
    const api = useApi();
    const store = useLocalObservable<AppointmentListStore>(() => new AppointmentListStore(api, undefined, false));
    const [appointments, setAppointments] = useState<AppointmentClientDto[]>([]);

    useEffect(() => {
        store.setFilter({ dateFrom: null, dateTo: null, sortDirection: 'desc', ...filter });
    }, [filter]);

    useEffect(() => {
        setAppointments(store.pagedData?.data);
    }, [store.pagedData?.data]);

    const refresh = useCallback(async () => {
        await store.load()
    }, []);

    const filterDateFrom = useMemo(() => store.filter.dateFrom ? moment(store.filter.dateFrom) : null, [store.filter.dateFrom]);
    const filterDateTo = useMemo(() => store.filter.dateTo ? moment(store.filter.dateTo) : null, [store.filter.dateTo]);

    return <FadeIn>
        <div className={styles.listWrapper}>
            <div className={styles.listFilter}>
                <div className={styles.listFilterItem}>
                    <Input
                        placeholder="Search"
                        defaultValue={store.filter.search || ""}
                        allowClear
                        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => store.setSearch(e.currentTarget.value)} />
                </div>
                <div className={styles.listFilterItem}>
                    <MyDatePicker.RangePicker
                        value={[filterDateFrom, filterDateTo]}
                        showTime={false}
                        style={{ width: '100%' }}
                        onChange={dates => {
                            store.setFilter({ dateFrom: dates?.[0].toDate(), dateTo: dates?.[1].toDate() });
                        }} />
                </div>
            </div>
            <div className={styles.appointmentListContainer}>
                <div className='flex align-center justify-between'>
                    <Button onClick={refresh} loading={store.loading} className={styles.refreshButton} icon={<UndoOutlined />} style={{ marginLeft: 'auto' }}>
                        Refresh
                    </Button>
                </div>
                {store.loading
                    ? <Skeleton active />
                    : <div className={styles.appointmentList}>
                        {appointments?.length > 0
                            ? <List
                                dataSource={appointments}
                                renderItem={appointment =>
                                    <List.Item key={appointment.id} className='p-0 mb-10' style={{ borderBlockEnd: 'none' }}>
                                        <OneAppointment appointment={appointment} onAppointmentClick={() => onAppointmentClick(appointment)} />
                                    </List.Item>}
                            />
                            : <div className='flex flex-col align-center justify-center fs-22 fw-500 color-black'>
                                <span>No appointments found</span>
                                <span className='fs-16 color-grey'>To start recording you need to have an appointment.</span>
                            </div>}
                    </div>}
            </div>
        </div>
    </FadeIn>;
});