import React from "react";
import { Alert, Breadcrumb, Button, Checkbox, Divider, Drawer, Input, Popconfirm, Row, Select, Space, Table, TablePaginationConfig, Tag, Tooltip, Typography, notification } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Appointment } from "api/model/Appointments/Appointment";
import { FeedbackListStore } from "./com/FeedbackListStore";
import { AdminFeedbackDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto";
import { AppointmentFeedbackStatus, AppointmentFeedbackStatusList } from "api/model/Appointments/enums/AppointmentFeedbackStatus";
import UserSelect from "app/components/UserSelect";
import DoctorSelect from "app/components/DoctorSelect";
import { AppointmentFeedbackReviewTier, AppointmentFeedbackReviewTierList } from "api/model/Appointments/enums/AppointmentFeedbackReviewTier";
import PracticeSelect from "app/components/PracticeSelect";
import useVSHub from "misc/hooks/useVSHub";
import { AdminFeedbackUpdateDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackUpdateDto";
import { AdminFeedbackUpdateChatDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackUpdateChatDto";
import useCurrentUser from "misc/hooks/useCurrentUser";
import DatePicker from "app/components/DatePicker";
import AccountSelect from "app/components/AccountSelect";


const FeedbackList: React.FC = observer(() => {
	const api = useApi();
	const user = useCurrentUser();
	const store = useLocalObservable<FeedbackListStore>(() => new FeedbackListStore(api, user));
	const hub = useVSHub();

	useEffect(() => {
		store.notificationApi = notification;
	}, [])

	useEffect(() => {
		if (!hub.connected) return;

		hub.subscribe(`admin-feedback-updates`);
		hub.on<string>(`update-feedback`, d => {
			store.updateFeedback(JSON.parse(d) as AdminFeedbackUpdateDto);
		})
		hub.localSubscribe<string>(`update-chat`, updateFeedbackChat);

		return () => {
			hub.unsubscribe(`admin-feedback-updates`);
			hub.off<string>(`update-feedback`)
			hub.localUnsubscribe(`update-chat`, updateFeedbackChat);
		}
	}, [hub.connected]);

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<Appointment> | SorterResult<Appointment>[] | any) => {
		store.setSortingPaging(sorter.columnKey, sorter.order, pagination.current, pagination.pageSize);
	};

	const updateFeedbackChat = (data: string) => {
		var parsed = JSON.parse(data) as AdminFeedbackUpdateChatDto;
		store.updateFeedbackChat(parsed);
	}

	//@ts-ignore
	window['test'] = store.updateFeedbackChat;

	let columns: ColumnsType<AdminFeedbackDto> = [
		{
			title: 'Submitted',
			key: 'SubmittedDate',
			ellipsis: true,
			sorter: true,
			defaultSortOrder: "descend",
			width: 200,
			render: (x: AdminFeedbackDto) => <>
				<Link to={`/recordings/${x.id}`} >
					{x.submittedDate ?
						moment(x.submittedDate).format("MM/DD/YYYY HH:mm a") :
						moment(x.createdDate).format("MM/DD/YYYY HH:mm a")
					}
				</Link>
				<Tooltip title="Created date">
					<div className='secondary-text' style={{ fontSize: "11px" }}>
						{moment(x.createdDate).format("MM/DD/YYYY HH:mm a")}
					</div>
				</Tooltip>

				{x.appointment?.manualCreated && <Tag style={{ marginTop: 5 }} color={"green"}>MANUAL</Tag>}
				{x.ambientMode && <><br /><Tag style={{ marginTop: 5 }} color={"blue"}>AMBIENT MODE</Tag></>}
			</>
		},
		{
			title: 'ID',
			key: 'Id',
			render: (x: AdminFeedbackDto) => {
				return <>
					{user.isSAASUser == false && <div style={{ fontSize: 12, fontWeight: 'bold' }}>{x.appointment.accountName}</div>}
					<Link to={`/recordings/${x.id}`} >
						{x.id}
					</Link>
				</>
			}
		},
		{
			title: 'Chat',
			key: 'Chat',
			render: (x: AdminFeedbackDto) => {
				return <>
					<Tag
						color={x.chatOpen ? "blue" : "default"}>
						{x.chatOpen ? "Open" : "Closed"}
					</Tag>

					{x.chatAgentAttention &&
						<Tag color="red" style={{ marginTop: 10 }}>New message</Tag>
					}
				</>
			}
		},
		{
			title: 'Tier',
			key: 'Tier',
			render: (x: AdminFeedbackDto) => {
				const { label: tierLabel, color: tierColor } = AppointmentFeedbackReviewTierList.find(b => b.value == x.reviewTier);

				return <>
					<Tag color={tierColor}>@{tierLabel.toUpperCase()}</Tag> <br />
				</>
			}
		},
		{
			title: 'Doctor & Hospital',
			key: 'DoctorName',
			ellipsis: true,
			render: (x: AdminFeedbackDto) => <>
				<div>{x.appointment?.doctorName ? x.appointment.doctorName : <span className="secondary-text">Not Assigned</span>}</div>
				<div>{x.practiceName ? x.practiceName : <span className="secondary-text">Not Assigned</span>}</div>
			</>
		},
		{
			title: 'Pet & Client',
			key: 'PetClient',
			ellipsis: true,
			render: (x: AdminFeedbackDto) => <>
				<Link to={`/recordings/${x.id}`} >
					<div>{x.appointment?.patientName ? x.appointment.patientName : <span className="secondary-text">Not Assigned</span>}</div>
					<div>{x.appointment?.clientName ? x.appointment.clientName : <span className="secondary-text">Not Assigned</span>}</div>
				</Link>
			</>
		},
		{
			title: 'Now Editing',
			key: 'NowEditing',
			ellipsis: true,
			render: (x: AdminFeedbackDto) => <>
				{x.isEditing == true ?
					<span>
						{x.editorName ? x.editorName : <span className="secondary-text">Not Assigned</span>}
					</span>
					:
					<span>-</span>
				}
			</>
		},
		{
			title: 'Editors',
			key: 'Editors',
			render: (x: AdminFeedbackDto) => <>
				{x.editors.length > 0 ? x.editors.map((x, i) => <Tag key={x + i}>{x}</Tag>) : "-"}
			</>
		},
		{
			title: 'Rating',
			key: 'Rating',
			ellipsis: true,
			render: (x: AdminFeedbackDto) => <>
				<span>
					{x.rating || "-"}
				</span>
			</>
		},
		{
			title: 'Status',
			width: 160,
			render: (x: AdminFeedbackDto) => {
				const feedBackStatusMeta = AppointmentFeedbackStatusList.find(s => s.value == x.status);
				return <>
					<Tooltip title={feedBackStatusMeta?.tooltip} placement="left">
						<Tag color={feedBackStatusMeta?.color}>{feedBackStatusMeta?.label.toUpperCase() || x.status}</Tag>
					</Tooltip>
					{x.addedToPims && <Tag color="green">Added to PIMS</Tag>}
				</>
			}
		}
	];

	if (user.isSAASUser || user.isRestrictedUser) {
		var noSaasUserCols = ["Tier", "Chat", "Rating"]
		columns = columns
			.filter(x => noSaasUserCols.includes(x.key as string) == false);
	}



	return <div className="vs_table_wrapper">
		{/* {store.pagedData?.data[1].chatAgentAttention ? "YES" : "NO"} */}

		<Space style={{ marginBottom: 10 }} wrap>
			<Input.Search
				style={{ minWidth: 200 }}
				placeholder="Search"
				defaultValue={store.filter.search || ""}
				onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => store.setSearch(e.currentTarget.value)}
			/>

			{/* <UserSelect value={store.filter.editorId} placeholder={"Editor"} onChange={(value) => store.setFilter({ editorId: value })} /> */}
			{user.isSuperAdmin &&
				<AccountSelect value={store.filter.accountId} onChange={(value) => store.setFilter({ accountId: value })} />
			}

			<DoctorSelect value={store.filter.doctorId} onChange={(value) => store.setFilter({ doctorId: value })} />
			<PracticeSelect onChange={x => store.setFilter({ practiceId: x })}></PracticeSelect>

			<Select style={{ width: 200 }}
				defaultValue={store.filter.status}
				onChange={(value: AppointmentFeedbackStatus | null) => store.setFilter({ status: value })}>
				<Select.Option value={AppointmentFeedbackStatus.All}>All statuses</Select.Option>
				{/* <Select.Option value={AppointmentFeedbackStatus.Draft}>Draft</Select.Option> */}
				<Select.Option value={AppointmentFeedbackStatus.Markup}>Markup</Select.Option>
				<Select.Option value={AppointmentFeedbackStatus.MarkupDone}>Markup Done</Select.Option>
				<Select.Option value={AppointmentFeedbackStatus.Completed}>Completed</Select.Option>
			</Select>

			{!user.isSAASUser && !user.isRestrictedUser &&
				<Select style={{ width: 200 }}
					placeholder="Review Tier"
					defaultValue={store.filter.reviewTier}
					onChange={(value: AppointmentFeedbackReviewTier | null) => store.setFilter({ reviewTier: value })}>
					<>
						{AppointmentFeedbackReviewTierList.map(b =>
							<Select.Option value={b.value}>{b.label}</Select.Option>)
						}
					</>
				</Select>
			}

			<DatePicker.RangePicker onChange={(fromTo) => {
				var from = fromTo && fromTo[0] ? moment(fromTo[0]).startOf('day').utc().toDate() : null;
				var to = fromTo && fromTo[1] ? moment(fromTo[1]).endOf('day').utc().toDate() : null;
				store.setFilter({
					dateFrom: from ? moment(from).toDate() : null,
					dateTo: to ? moment(to).toDate() : null
				})
			}} />

			<Checkbox
				indeterminate={store.filter.addedToPims == null}
				checked={store.filter.addedToPims === true} onClick={() => store.toggleAddedToPims()}
			>
				Added to PIMS
			</Checkbox>
		</Space>

		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
				<Table
					onChange={handleTableChange}
					loading={store.loading}
					rowKey={x => x.id ? x.id : ""}
					columns={columns}
					dataSource={store.pagedData?.data}
					rowClassName={(record, index) => store.isFeedbackImportant(record) ? "deadline-feedback" : "transparent"}
					pagination={{
						hideOnSinglePage: true,
						current: store.pagedData?.page,
						total: store.pagedData?.total,
						pageSize: store.pagedData?.pageSize,
						showSizeChanger: true,
						showTotal: (total: number) => `Total: ${total}`
					}}
				/>
		}
	</div>;


})


export default FeedbackList;
