import { AudioOutlined, DownOutlined, HomeOutlined, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { UserRoleEnum } from 'api/model/User/UserRole';
import combineClasses from 'misc/helpers/combineClasses';
import useCurrentUser from 'misc/hooks/useCurrentUser';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './AdminMenu.module.scss';

type MenuItem = {
	title: React.ReactNode;
	icon?: React.ReactNode;
	path?: string;
	submenu?: { title: string; path: string }[];
};

const AdminMenu: React.FC = () => {
	const user = useCurrentUser();
	const nav = useNavigate();
	const location = useLocation();
	const [current, setCurrent] = useState<string | null>('/rec');
	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

	const handleClick = (path: string, e: any) => {
		if (e.ctrlKey || e.metaKey) {
			window.open(path, '_blank');
			return;
		}
		nav(path);
	};

	// Update current path based on location
	useEffect(() => {
		const regex = /^\/([^\/]+)/;
		const match = location.pathname.match(regex);
		setCurrent(match ? match[0] : null);
	}, [location.pathname]);

	// Update window width on resize
	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const alwaysVisibleItems: MenuItem[] = [
		{ title: 'Home', icon: <HomeOutlined />, path: '/rec' },
		{ title: 'Recordings', icon: <AudioOutlined />, path: '/recList' },
		{ title: 'Help Center', icon: <QuestionCircleOutlined />, path: '/help-center' },
	];

	const dropdownItems: MenuItem[] = [
		user.isInRole([UserRoleEnum.SuperAdmin]) && { title: 'Appointments', path: '/appointments' },
		(user.isInRole([UserRoleEnum.SuperAdmin])
			|| user.isInRole([UserRoleEnum.AccountOwner, UserRoleEnum.AccountManager]))
		&& { title: 'Transcriptions', icon: <AudioOutlined />, path: '/recordings' },
		user.isInRole([
			UserRoleEnum.SuperAdmin,
			UserRoleEnum.PracticeSuperUser,
			UserRoleEnum.AccountOwner,
			UserRoleEnum.AccountManager,
		]) && { title: 'Templates', path: '/templates' },
		(user.currentAccountId && user.isInRole([UserRoleEnum.AccountOwner]) || user.isInRole([UserRoleEnum.SuperAdmin])) && { title: 'Hospitals', path: '/practices' },
		(user.currentAccountId && user.isInRole([UserRoleEnum.AccountOwner]) || user.isInRole([UserRoleEnum.SuperAdmin])) && { title: 'Doctors', path: '/doctors' },
		(user.currentAccountId && user.isInRole([UserRoleEnum.AccountOwner]) || user.isInRole([UserRoleEnum.SuperAdmin])) && { title: 'Users', path: '/users' },
		user.isInRole([UserRoleEnum.SuperAdmin]) && { title: 'Offices', path: '/offices' },
		user.isInRole([UserRoleEnum.SuperAdmin]) && { title: 'Clients', path: '/clients' },
		user.isInRole([UserRoleEnum.SuperAdmin]) && { title: 'Patients', path: '/patients' },
		user.isInRole([UserRoleEnum.SuperAdmin]) && { title: 'Words Dict', path: '/voice_words' },
		user.isInRole([UserRoleEnum.SuperAdmin]) && { title: 'Billing', path: '/billing' },
		user.isInRole([UserRoleEnum.SuperAdmin]) && {
			title: 'Analytics',
			submenu: [
				{ title: 'Users', path: '/analytics/users' },
				{ title: 'Totals', path: '/analytics/totals' },
			],
		},
		user.isInRole([UserRoleEnum.SuperAdmin]) && { title: 'Accounts', path: '/accounts' },
	].filter(Boolean);

	const moreButtonActive = dropdownItems.some((item) => item.path === current || item.submenu?.some((sub) => sub.path === current));

	const dropdownMenu = (
		<Menu className={styles.dropdownMenu} triggerSubMenuAction={'click'}>
			{dropdownItems.map((item) => {
				if (item.submenu) {
					return <Menu.SubMenu key={item.title?.toString()} title={item.title}>
						{item.submenu.map((sub) => (
							<Menu.Item key={sub.path}
								style={sub.path === current ? { backgroundColor: '#EF6E27', color: '#FFF' } : {}}>
								<a href={sub.path}
									target="_self"
									onClick={(e) => {
										if (e.ctrlKey || e.metaKey || e.button === 1) {
											return;
										}
										e.preventDefault();
										handleClick(sub.path, e);
									}}>
									{sub.title}
								</a>
							</Menu.Item>
						))}
					</Menu.SubMenu>;
				}

				const active = item.path === current;
				return <Menu.Item
					key={item.path}
					style={active ? { backgroundColor: '#EF6E27', color: '#FFF' } : {}}>
					<a href={item.path}
						target="_self"
						onClick={(e) => {
							if (e.ctrlKey || e.metaKey || e.button === 1) {
								return;
							}
							e.preventDefault();
							handleClick(item.path, e);
						}}>
						{item.title}
					</a>
				</Menu.Item>;
			})}
		</Menu >
	);

	const filteredAlwaysVisibleItems = (() => {
		if (windowWidth > 1100) {
			return alwaysVisibleItems;
		}
		if (windowWidth > 900) {
			return alwaysVisibleItems.filter((item) => item.title !== 'Help Center');
		}
		return alwaysVisibleItems.filter((item) => item.title === 'Home');
	})();

	return <div className={styles.menu}>
		{filteredAlwaysVisibleItems.map((item) => {
			const active = item.path === current;
			return <a key={item.path}
				href='_self'
				className={combineClasses(styles.menu_item, active && styles.menu_item__active)}
				onClick={(e) => {
					if (e.ctrlKey || e.metaKey || e.button === 1) {
						return;
					}
					e.preventDefault();
					handleClick(item.path, e);
				}}>
				{item.icon}
				<span>{item.title}</span>
			</a>;
		})}

		{dropdownItems.length > 0 && (
			<Dropdown overlay={dropdownMenu} trigger={['click']} placement="bottomCenter" overlayClassName={styles.menu_dropdown}>
				<div className={combineClasses(styles.menu_item, moreButtonActive && styles.menu_item__active)}>
					<SettingOutlined />
					<DownOutlined />
				</div>
			</Dropdown>
		)}
	</div>;


	// return <div>
	// 	<div>
	// 		<img src={''} width={125} />
	// 		<div onClick={handleCollapse}>
	// 			{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
	// 		</div>
	// 	</div>

	// 	<Menu selectedKeys={[loc.pathname]} inlineCollapsed={collapsed}>
	// 		<Menu.Item key={"/rec"} className='menu-item stress flex align-center' icon={<AudioFilled style={{ color: COLORS.ligthRed }} />}>
	// 			<Link to='/rec'>
	// 				Start Recording
	// 			</Link>
	// 		</Menu.Item>




	// 		<Menu.Item key={"/recordings"} className='menu-item flex align-center' icon={<AudioOutlined />}>
	// 			<Link to='/recordings' className='flex align-center justify-between'>
	// 				<span>Transcriptions</span>
	// 				{/* <div className='flex ml-5'>
	// 					{user.isInRole([UserRoleEnum.Supervisor]) && user.unreadChats.length > 0 &&
	// 						<Tooltip title="Open Chats">
	// 							<Badge count={user.unreadChats.length} />
	// 						</Tooltip>
	// 					}
	// 					{user.recordingsTotal > 0 &&
	// 						<Tooltip title="Total">
	// 							<Badge count={user.recordingsTotal} />
	// 						</Tooltip>
	// 					}
	// 					{user.recordingsVet > 0 &&
	// 						<Tooltip title="Vet">
	// 							<Badge count={user.recordingsVet} color={'#ff9800'} />
	// 						</Tooltip>
	// 					}
	// 					{user.recordingsSupervisor > 0 &&
	// 						<Tooltip title="Supervisor">
	// 							<Badge count={user.recordingsSupervisor} color={'#d4380d'} />
	// 						</Tooltip>
	// 					}
	// 				</div> */}
	// 			</Link>
	// 		</Menu.Item>

	// 		{user.isInRole([UserRoleEnum.SuperAdmin]) &&
	// 			<Menu.Item key={"/appointments"} className='menu-item' icon={<CalendarOutlined />}>
	// 				<Link to='/appointments'>
	// 					Appointments
	// 				</Link>
	// 			</Menu.Item>
	// 		}

	// 		{user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.PracticeSuperUser, UserRoleEnum.AccountOwner, UserRoleEnum.AccountManager]) &&
	// 			<Menu.Item key={"/templates"} className='menu-item' icon={<FileOutlined />}>
	// 				<Link to='/templates'>
	// 					Templates
	// 				</Link>
	// 			</Menu.Item>
	// 		}

	// 		<Menu.Divider />

	// 		{user.currentAccountId && user.isInRole([UserRoleEnum.AccountOwner]) &&
	// 			<>
	// 				<Menu.Item key={"/practices"} className='menu-item' icon={<BankOutlined />}>
	// 					<Link to='/practices'>
	// 						Hospitals
	// 					</Link>
	// 				</Menu.Item>
	// 				<Menu.Item key={"/doctors"} className='menu-item' icon={<UserOutlined />}>
	// 					<Link to='/doctors'>
	// 						Doctors
	// 					</Link>
	// 				</Menu.Item>
	// 				<Menu.Item key={"/users"} className='menu-item' icon={<TeamOutlined />}>
	// 					<Link to='/users'>
	// 						Users
	// 					</Link>
	// 				</Menu.Item>
	// 				{/* <Menu.Item key={"/account-billing"} className='menu-item' icon={<DollarOutlined />}>
	//           <Link to='/account-billing'>
	//             Billing
	//           </Link>
	//         </Menu.Item> */}
	// 			</>
	// 		}

	// 		{user.isInRole([UserRoleEnum.SuperAdmin]) &&
	// 			<>
	// 				<Menu.Divider title='Super Admin Area' />
	// 				<Menu.Item key={"/users"} className='menu-item' icon={<TeamOutlined />}>
	// 					<Link to='/users'>
	// 						Users
	// 					</Link>
	// 				</Menu.Item>
	// 				<Menu.Item key={"/practices"} className='menu-item' icon={<BankOutlined />}>
	// 					<Link to='/practices'>
	// 						Hospitals
	// 					</Link>
	// 				</Menu.Item>
	// 				<Menu.Item key={"/offices"} className='menu-item' icon={<ClusterOutlined />}>
	// 					<Link to='/offices'>
	// 						Offices
	// 					</Link>
	// 				</Menu.Item>
	// 				<Menu.Item key={"/doctors"} className='menu-item' icon={<UserOutlined />}>
	// 					<Link to='/doctors'>
	// 						Doctors
	// 					</Link>
	// 				</Menu.Item>
	// 				<Menu.Item key={"/clients"} className='menu-item' icon={<TeamOutlined />}>
	// 					<Link to='/clients'>
	// 						Clients
	// 					</Link>
	// 				</Menu.Item>
	// 				<Menu.Item key={"/patients"} className='menu-item' icon={<DatabaseOutlined />}>
	// 					<Link to='/patients'>
	// 						Patients
	// 					</Link>
	// 				</Menu.Item>
	// 				<Menu.Item key={"/voice_words"} className='menu-item' icon={<AudioOutlined />}>
	// 					<Link to='/voice_words'>
	// 						Words Dict
	// 					</Link>
	// 				</Menu.Item>
	// 				<Menu.Item key={"/billing"} className='menu-item' icon={<DollarOutlined />}>
	// 					<Link to='/billing'>
	// 						Billing
	// 					</Link>
	// 				</Menu.Item>

	// 				<Menu.SubMenu key="/analytics" className='menu-item' icon={<DashboardOutlined />} title="Analytics">
	// 					<Menu.Item key="/analytics/users">
	// 						<Link to='/analytics/users'>Users</Link>
	// 					</Menu.Item>
	// 					<Menu.Item key="/analytics/totals">
	// 						<Link to='/analytics/totals'>Totals</Link>
	// 					</Menu.Item>
	// 				</Menu.SubMenu>
	// 				<Menu.Item key={"/accounts"} className='menu-item' icon={<ClusterOutlined />}>
	// 					<Link to='/accounts'>
	// 						Accounts
	// 					</Link>
	// 				</Menu.Item>
	// 			</>
	// 		}
	// 		<Menu.Item key={"/help-center"} className='menu-item' icon={<QuestionCircleOutlined />}>
	// 			<Link to='/help-center'>
	// 				Help Center
	// 			</Link>
	// 		</Menu.Item>

	// 		<Menu.Divider />

	// 		<Menu.Item key={"/auth/logout"} className='menu-item' icon={<LogoutOutlined />}>
	// 			<Link to='/auth/logout'>
	// 				Logout
	// 			</Link>
	// 		</Menu.Item>
	// 	</Menu>
	// </div>;
}

export default observer(AdminMenu);
