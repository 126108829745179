import { Alert, Input, Button, Form, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import useApi from "misc/hooks/useApi";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "app/components/Logo";
import combineClasses from "misc/helpers/combineClasses";
import styles from './Auth.module.scss';
import PasswordInput from "routes/components/PasswordInput/PasswordInput";

const NewPassword: React.FC = () => {
    const api = useApi();
    const [form] = useForm();
    const navigate = useNavigate();
    const [state, setState] = useState<{ error: string | null, loading: boolean }>({ error: "", loading: false });

    const submit = async (values: { password: string, passwordConfirm: string }) => {
        setState({ ...state, loading: true, error: "" })
        try {
            const result = await api.user.newPassword(values.password, values.passwordConfirm);
            if (result.error || !result.success) {
                setState({ ...state, loading: false, error: result.error || "" })
                return;
            }
        } catch (e) {
            console.error(e);
        }

        setState({ ...state, loading: false, error: null })
        navigate("/");
    }

    return <div className={combineClasses(styles.auth_wrapper, "fadeInLeft")}>
        <div className={styles.auth_content}>
            <Logo size={400} />
            <h1>Password Restore</h1>
            <p>Back to <Link to="/auth/login" className={styles.authLink}>Login</Link></p>
        </div>
        <Form form={form} onFinish={submit} layout={"vertical"} style={{ maxWidth: "450px", margin: "0 auto" }}  >
            {state.error && <Alert style={{ marginBottom: 10 }} type="warning" message={state.error} />}

            <PasswordInput label="New Password" className="body-form-item" name="password" placeholder={"Password"} />

            <Form.Item className="body-form-item" label="Repeat New Password" name="passwordConfirm" rules={[{ required: true, message: 'Please input one more time your password' }]}>
                <Input placeholder={"Confirm Password"} type="password" />
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
                <Button className="bc-btn" size={"large"} type="primary" htmlType="submit" loading={state.loading}>Submit</Button>
            </Form.Item>
        </Form>
    </div>
}

export default NewPassword;

