import React from "react";
import { Typography } from "antd";
import { Button } from "antd";
import { observer } from "mobx-react";
import appStore from '../../../assets/appstore.svg';
import googlePlay from '../../../assets/googleplay.svg'
import styles from './QuickStart.module.scss';
import combineClasses from "misc/helpers/combineClasses";
import { ArrowLeftOutlined } from "@ant-design/icons";

const DoneStep: React.FC<{ onBack: () => void; onNext: () => void }> = observer(({ onBack, onNext }) => {

    return <>
        <div className={styles.initial_setup_form}>

            <Typography.Title level={4} className='text-left'>
                Download the VetSkribe App
            </Typography.Title>

            <Typography.Paragraph>
                <ol>
                    <li>Download and open the VetSkribe App on your phone.</li>

                    <div className={combineClasses(styles.apps_buttons, 'm-0 mt-10 mb-10')}>
                        <div onClick={() => window.location.href = 'https://apps.apple.com/us/app/vetskribe/id1634975470'}>
                            <img width={100} src={appStore} alt="Download from App Store" />
                        </div>
                        <div onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.monarchy.vetscribe'}>
                            <img width={100} src={googlePlay} alt="Download from Google Play" />
                        </div>
                    </div>

                    <li>Login with your (or any other doctor created) email and password.</li>
                    <li>Press on “Appointments” and create a patient using the “+” icon.</li>
                    <li>Select the template you want to use.</li>
                    <li>Choose between “Ambient Listening” or “Dictation”.</li>
                    <li>After your recording is complete, click “Submit”.</li>
                    <li>You’re all set!  Within minutes, the transcription of your recording will be available in your app or in your dashboard.</li>
                </ol>
            </Typography.Paragraph>
            <footer className={styles.form_footer}>
                <div className="flex flex-col align-center">
                    <div onClick={() => onBack()} className={styles.authBtn}>
                        <ArrowLeftOutlined />
                        Back
                    </div>
                </div>
                <div className="flex flex-col align-center">
                    <div onClick={() => onNext()} className={styles.authBtnOrange}>
                        Complete
                    </div>
                </div>
            </footer>
        </div>

    </>
});

export default DoneStep;