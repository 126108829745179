import { CaretDownFilled, EditOutlined } from '@ant-design/icons';
import { Breadcrumb, Drawer, Skeleton, Tag, notification } from 'antd';
import { AppointmentFeedbackStatus, getAppointmentFeedbackStatusLabel } from 'api/vs-recording/model/Appointments/enums/AppointmentFeedbackStatus';
import { AppointmentClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentClientDto';
import { AppointmentCreateDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentCreateDto';
import { AppointmentFeedbackClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto';
import { getLangShortLabel } from 'misc/helpers/getLangLabel';
import useApi from 'misc/hooks/useApi';
import useCurrentUser from 'misc/hooks/useCurrentUser';
import useDrawer from 'misc/hooks/useDrawer';
import useVSHub from 'misc/hooks/useVSHub';
import { observer, useLocalObservable } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AppointmentStore from 'vs-recording/stores/appointment/AppointmentStore';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import { COLORS } from 'vs-recording/styles/Colors';
import AppointmentForm from '../Schedule/AppointmentForm/AppointmentForm';
import { AppointmentListStore } from '../Schedule/AppointmentList/AppointmentListStore';
import styles from './Recording.module.scss';
import EditFeedback from './components/EditFeedback';
import Processing from './components/Processing';
import SubmitFeedback from './components/SubmitFeedback/SubmitFeedback';
import Submitted from './components/Submitted';
import TemplateList from './components/TemplateList';
import { useLocation, useNavigate } from 'react-router-dom';
import FadeIn from 'app/components/FadeIn/FadeIn';

type RecordingProps = {
    appointmentId: number;
    listStore: AppointmentListStore;
    onDeleteOrBack: () => void;
};

const Recording: React.FC<RecordingProps> = observer(({ appointmentId, listStore, onDeleteOrBack }) => {
    const api = useApi();
    const user = useCurrentUser();
    const [stage, setStage] = useState<'template' | 'draft' | 'submitted' | 'processing' | 'editing'>('template');
    const store = useLocalObservable<AppointmentStore>(() => new AppointmentStore(api, user));
    const [appointment, setAppointment] = useState<AppointmentClientDto>();
    const [feedback, setFeedback] = useState<AppointmentFeedbackClientDto>();
    const appointmentDrawer = useDrawer<AppointmentCreateDto>();
    const templateDrawer = useDrawer<AppointmentFeedbackClientDto>();
    const hub = useVSHub();
    const nav = useNavigate();
    const location = useLocation();

    const statusData = getAppointmentFeedbackStatusLabel(feedback?.status);

    useEffect(() => {
        if (!hub.connected || !feedback?.id) return;
        hub.subscribe(`admin-feedback-updates-${feedback?.id}`);
        hub.on<string>(`update-feedback-${feedback?.id}`, updateFeedbackFromHub)
        return () => {
            hub.unsubscribe(`admin-feedback-updates-${feedback?.id}`);
            hub.off<string>(`update-feedback-${feedback?.id}`)
        }
    }, [hub.connected, feedback?.id]);

    useEffect(() => {
        const originalTitle = document.title;
        if (appointment)
            document.title = `Recording | ${appointment.patientName}`;

        return () => {
            document.title = originalTitle;
        };
    }, [appointment]);

    const updateFeedbackFromHub = useCallback(async (data: string) => {
        var res = JSON.parse(data) as AppointmentFeedbackClientDto;
        if (feedback?.id == res.id)
            setFeedback(prevFb => ({ ...prevFb, ...res }));
    }, [feedback?.id]);

    useEffect(() => {
        load();
    }, [appointmentId]);

    useEffect(() => {
        store.error && notification.error({ message: store.error, duration: 2 });
    }, [store.error]);

    useEffect(() => {
        setAppointment(store.appointment);
        setFeedback(store.appointment?.feedback);
    }, [store.appointment, store.appointment?.feedback]);

    const load = useCallback(async () => {
        if (!appointmentId) return;
        await store.load(appointmentId);
        setAppointment(store.appointment);
        setFeedback(store.appointment?.feedback)
    }, [appointmentId]);

    useEffect(() => {
        if (!feedback) return;

        if (feedback.templateId == null) {
            setStage('template');
            return;
        }
        switch (feedback.status) {
            case AppointmentFeedbackStatus.Processing:
            case AppointmentFeedbackStatus.ReadyToProcess:
                setStage('processing');
                break;
            case AppointmentFeedbackStatus.MarkupDone:
            case AppointmentFeedbackStatus.Markup:
            case AppointmentFeedbackStatus.Completed:
                setStage('submitted');
                break;
            case AppointmentFeedbackStatus.EditingInTheApp:
                setStage('editing');
                break;
            case AppointmentFeedbackStatus.Draft:
            case AppointmentFeedbackStatus.PrepocessingDone:
            default:
                setStage('draft');
        }
    }, [feedback, feedback?.templateId, feedback?.status]);

    const setSelectedTemplate = useCallback(async (id: number) => {
        if (((id == appointment?.feedback?.templateId))) {
            setStage('draft');
        } else {
            await store.setTemplateId(id);
        }
    }, [store, appointment?.feedback?.templateId]);

    const editAppointment = useCallback(async (a: AppointmentCreateDto) => {
        try {
            await listStore.createAppointment(a);
            await load();
        } catch (e: any) {
            notification.error({ message: e.message, duration: 2 });
        }
    }, []);

    const showStatus = useMemo(() => {
        const status = store.appointment?.feedback?.status;
        if (!status) return false;
        return status == AppointmentFeedbackStatus.Processing || status == AppointmentFeedbackStatus.ReadyToProcess || status == AppointmentFeedbackStatus.Markup || status == AppointmentFeedbackStatus.EditingInTheApp || status == AppointmentFeedbackStatus.MarkupDone || status == AppointmentFeedbackStatus.Completed;
    }, [store.appointment?.feedback?.status]);

    const canEdit = useMemo(() => {
        return store.appointment?.feedback?.status == AppointmentFeedbackStatus.Draft;
    }, [store.appointment?.feedback?.status]);


    // region: handle redirect to rec and recList
    const needToRedirectToRecList = useMemo(() =>
        feedback?.status == AppointmentFeedbackStatus.Completed ||
        feedback?.status == AppointmentFeedbackStatus.Markup ||
        feedback?.status == AppointmentFeedbackStatus.MarkupDone ||
        feedback?.status == AppointmentFeedbackStatus.EditingInTheApp
        , [feedback?.status]);

    // dummy code to check if need to redirect to recList
    useEffect(() => {
        if (!feedback?.status) return;
        if (needToRedirectToRecList) {
            if (location.pathname.includes('/rec/')) {
                nav(`/recList/appointment/${feedback?.appointmentId}`);
            }
        } else {
            if (location.pathname.includes('/recList/')) {
                nav(`/rec/appointment/${feedback?.appointmentId}`);
            }
        }
    }, [feedback?.status]);

    // endregion

    if (store.loading) return <FadeIn delay={300}><Skeleton active /></FadeIn>;
    return <AppointmentStoreContext.Provider value={store}>
        <FadeIn>
            <div className={styles.wrapper}>
                <header className='flex align-center justify-between mb-10'>
                    <div className='flex align-center gap-5'>
                        <div className='flex flex-col'>
                            <div className='color-grey fw-500 fs-14'>
                                {moment(appointment?.date).format('LLL')}
                                {!Number.isNaN(appointment?.language) && <span> &#183; {getLangShortLabel(appointment?.language)}</span>}
                            </div>
                        </div>
                    </div>
                    <div className='flex'>
                        {showStatus &&
                            <Tag style={{ color: statusData.colors.color, background: statusData.colors.background, borderColor: statusData.colors.border }} className='fs-14'>
                                {statusData.label}
                            </Tag>}
                    </div>
                </header>
                <div className='flex align-center justify-between'>
                    <div>
                        <span className='color-orange fs-14'>Patient Name</span>
                        <div className='flex align-center gap-5 fs-18 fw-500'
                            style={canEdit ? { cursor: 'pointer', textDecorationLine: 'underline' } : {}}
                            onClick={() => {
                                if (canEdit)
                                    appointmentDrawer.open(appointment);
                            }}>
                            {appointment?.patientName}
                        </div>
                    </div>

                    <div className='flex flex-col align-end'>
                        {canEdit && <span className='color-orange fs-14'>Template</span>}
                        <div className='flex align-center gap-5'>
                            {canEdit ? <div className='flex align-center gap-5 fw-500 fs-18' style={{ cursor: 'pointer', textDecorationLine: 'underline' }} onClick={() => templateDrawer.open()}>
                                <span>{feedback?.template?.name}</span>
                            </div>
                                : <Tag style={{ color: COLORS.black, borderColor: COLORS.black, background: COLORS.white, fontWeight: 500 }}>
                                    {feedback?.template?.name}
                                </Tag>}
                        </div>
                    </div>
                </div>

                <div className={styles.content}>
                    {stage == 'template' &&
                        <TemplateList selectedId={feedback?.template?.id} onTemplateSelected={x => setSelectedTemplate(x.id)} />
                    }

                    {stage == 'draft' &&
                        <SubmitFeedback onBack={() => {
                            onDeleteOrBack();
                        }}
                            onDelete={() => {
                                store.deleteAppointment();
                                onDeleteOrBack();
                            }} />
                    }

                    {stage == 'processing' &&
                        <Processing feedback={feedback} />
                    }

                    {stage == 'editing' &&
                        <EditFeedback feedback={feedback} onDoneOrCancel={() => {
                            setStage('submitted');
                            load();
                        }} />
                    }

                    {stage == 'submitted' &&
                        <Submitted feedback={feedback} onEdit={() => setStage('editing')} onBack={onDeleteOrBack} onResubmit={() => setStage('draft')} />
                    }
                </div>
            </div>
        </FadeIn>

        <Drawer open={appointmentDrawer.visible}
            destroyOnClose
            title='New Appointment'
            onClose={appointmentDrawer.close}
            width={600}>
            <AppointmentForm appointment={appointmentDrawer.data}
                onCancel={appointmentDrawer.close}
                onDone={(a) => {
                    editAppointment(a);
                    appointmentDrawer.close();
                }} />
        </Drawer>

        <Drawer open={templateDrawer.visible}
            destroyOnClose
            title='Select Template'
            onClose={() => {
                templateDrawer.close();
                setStage('draft');
            }}
            width={600}>
            <TemplateList selectedId={feedback?.templateId}
                onTemplateSelected={x => {
                    setSelectedTemplate(x.id);
                    templateDrawer.close();
                }} />
        </Drawer>
    </AppointmentStoreContext.Provider>;

});

export default Recording;
