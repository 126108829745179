import { LanguageEnum, LanguageEnumExtended, LanguageEnumExtendedList, LanguageEnumList } from "api/model/User/Language";

export const getLangLabel = (value: LanguageEnum) => {
    const language = LanguageEnumExtendedList.find(lang => lang.value === value as any);
    return language ? language.label : 'Automatic';
};

export const getLangShortLabel = (value: LanguageEnum) => {
    const language = LanguageEnumList.find(lang => lang.value === value);
    return language ? language.shortLabel : 'Auto';
}