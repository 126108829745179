import { COLORS } from "vs-recording/styles/Colors";

export enum AppointmentFeedbackStatus {
	Draft = "Draft",
	Markup = "Markup",
	EditingInTheApp = "EditingInTheApp",
	MarkupDone = "MarkupDone",
	Completed = "Completed",
	ReadyToProcess = "ReadyToProcess",
	Processing = "Processing",
	All = "All",
	PrepocessingDone = "PrepocessingDone",
}

export type StatusColors = {
	color: string;
	background?: string;
	border?: string;
}

const AppointmentFeedbackStatusLabels = [
	{
		value: AppointmentFeedbackStatus.Draft,
		label: "Draft",
		color: COLORS.statusDraft,
		background: COLORS.statusDraftBg,
		border: COLORS.statusDraftBorder
	},
	{
		value: AppointmentFeedbackStatus.Markup,
		label: "Submitted",
		color: COLORS.statusSubmitted,
		background: COLORS.statusSubmittedBg,
		border: COLORS.statusSubmittedBorder
	},
	{
		value: AppointmentFeedbackStatus.MarkupDone,
		label: "Completed",
		color: COLORS.statusCompleted,
		background: COLORS.statusCompletedBg,
		border: COLORS.statusCompletedBorder
	},
	{
		value: AppointmentFeedbackStatus.Completed,
		label: "Approved",
		color: COLORS.statusApproved,
		background: COLORS.statusApprovedBg,
		border: COLORS.statusApprovedBorder
	},
	{
		value: AppointmentFeedbackStatus.Processing,
		label: "Processing",
		color: COLORS.statusProcessing,
		background: COLORS.statusProcessingBg,
		border: COLORS.statusProcessingBorder
	},
	{
		value: AppointmentFeedbackStatus.ReadyToProcess,
		label: "Ready to process",
		color: COLORS.statusReadyToProcess,
		background: COLORS.statusReadyToProcessBg,
		border: COLORS.statusReadyToProcessBorder
	},
	{
		value: AppointmentFeedbackStatus.EditingInTheApp,
		label: "Editing by you",
		color: COLORS.statusEditing,
		background: COLORS.statusEditingBg,
		border: COLORS.statusEditingBorder
	},
	{
		value: AppointmentFeedbackStatus.PrepocessingDone,
		label: "Preprocessing done",
		color: COLORS.statusPreprocessingDone,
		background: COLORS.statusPreprocessingDoneBg,
		border: COLORS.statusPreprocessingDoneBorder
	},
];

export type StatusData = {
	label: string;
	colors: StatusColors;
}

export const getAppointmentFeedbackStatusLabel = (status: AppointmentFeedbackStatus | null): { label: string, colors: StatusColors } => {
	const val = AppointmentFeedbackStatusLabels.find(x => x.value === status) || {
		color: COLORS.statusDraft,
		label: status || "",
		background: COLORS.statusDraftBg,
		border: COLORS.statusDraftBorder
	};
	return { label: val.label, colors: { color: val.color, background: val.background, border: val.border } };
}


