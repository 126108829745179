import { Alert, Breadcrumb, Divider, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { BillingDto, DoctorBillingDto } from "api/routes/Billing/Model/BillingDto";
import MyDatePicker from "app/components/DatePicker";
import PracticeSelect from "app/components/PracticeSelect";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { BillingStore } from "./BillingStore";
import { UserOutlined } from "@ant-design/icons";

const BillingList: React.FC = observer(() => {
	const api = useApi();
	const store = useLocalObservable<BillingStore>(() => new BillingStore(api));

	const doctorColumns: ColumnsType<DoctorBillingDto> = [
		{
			title: 'Doctor',
			key: 'Doctor Id',
			ellipsis: true,
			sorter: false,
			render: (x: DoctorBillingDto) => <>
				<Link to='' style={{ cursor: 'default' }}>{x.doctorName}</Link> <br />
				Doctor ID - {x.doctorId}
			</>
		},
		{
			align: "right",
			width: 100,
			render: (x: DoctorBillingDto) => <>
				{x.recordingCompleted}
			</>
		},
	];

	const columns: ColumnsType<BillingDto> = [
		{
			title: 'Practice Name',
			key: 'Practice Id',
			ellipsis: true,
			sorter: false,
			render: (x: BillingDto) => <>
				<strong>{x.practiceName}</strong> <br />
				ID - {x.practiceId}
			</>
		},
		{
			title: 'Month',
			key: 'Month',
			ellipsis: true,
			sorter: false,
			render: (x: BillingDto) => <>
				{x.year}-{x.month}
			</>
		},
		{
			title: 'Amount',
			align: "right",
			render: (x: BillingDto) => <>
				<span className="fs-16 fw-500">{x.recordingCompleted}</span>
			</>
		},
	];

	return <div className="vs_table_wrapper">
		<Space style={{ marginBottom: 10 }} wrap={false}>
			<MyDatePicker.RangePicker
				presets={[
					{ label: 'Today', value: [moment().startOf('day'), moment().endOf('day')] },
					{ label: 'Yesterday', value: [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')] },
					{ label: 'This week', value: [moment().startOf('week'), moment().endOf('week')] },
					{ label: 'Last week', value: [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')] },
					{ label: 'This month', value: [moment().startOf('month'), moment().endOf('month')] },
					{ label: 'Last month', value: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')] },
					{ label: 'This quarter', value: [moment().startOf('quarter'), moment().endOf('quarter')] },
					{ label: 'Last quarter', value: [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')] }
				]}
				popupAlign={{ points: ['bl', 'tl'] }}
				value={[moment(store.filter.dateFrom), moment(store.filter.dateTo)]}
				showTime={false}
				onChange={(dates) => {
					store.setFilter({ dateFrom: dates[0].toDate(), dateTo: dates[1].toDate() });
				}}
			/>
			<PracticeSelect onChange={(v) => store.setFilter({ practiceId: v })} value={store.filter.practiceId} />
		</Space>

		{store.error
			? <Alert message={store.error} type="error" />
			: <Table size="small"
				loading={store.loading}
				rowKey={x => `${x.practiceId}${x.month}${x.year}`}
				columns={columns}
				dataSource={store.pagedData?.data}
				pagination={false}
				expandable={{
					expandedRowRender: (record: BillingDto) => (
						<Table size="small"
							rowKey={x => `${x.doctorId}${record.month}${record.year}`}
							columns={doctorColumns}
							showHeader={false}
							dataSource={record.doctorBillings}
							pagination={false}
						/>
					)
				}}
			/>}
	</div>;
});

export default BillingList;
