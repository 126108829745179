import { LiveChatWidget } from '@livechat/widget-react';
import { AppSettings } from 'appSettings';
import useCurrentUser from 'misc/hooks/useCurrentUser';
import useGeneralState from 'misc/hooks/useGeneralState';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import styles from './AdminLayout.module.scss';
import Header from './Header/Header';

const AdminLayout: React.FC = observer(() => {
	const user = useCurrentUser();
	const generalState = useGeneralState();
	const nav = useNavigate();

	if (!user.isAuthorized) return <Navigate to="/auth/login" />;

	return <div className={styles.appWrapper}>
		<LiveChatWidget
			license={AppSettings.livechat.license}
			visibility="minimized"
			group='admin'
			customerName={user.firstName + ' ' + user.lastName}
			customerEmail={user.email}
			sessionVariables={{ account: user.accounts[0]?.name }}
		/>
		<Header />
		<div className={styles.contentWrapper}>
			<Outlet />
		</div>
	</div>;
});

export default AdminLayout;