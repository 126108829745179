// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pGeTbCcs5SZC0Qdo0ljT{width:100%;display:flex;flex-direction:column;justify-content:center;flex:1;background-color:#fff;position:relative;overflow:hidden}.pGeTbCcs5SZC0Qdo0ljT svg{width:100%}.pGeTbCcs5SZC0Qdo0ljT .nUDihDNNGWV43U4fhGxy{stroke:red;stroke-width:2}.pGeTbCcs5SZC0Qdo0ljT .KilFHg8dvL7NiBhZpU87{stroke:#ccc;stroke-width:1}.pGeTbCcs5SZC0Qdo0ljT .rLSUJrit1OopYPgUJZdf{fill:#000}", "",{"version":3,"sources":["webpack://./src/vs-recording/components/Recording/components/AudioVisualizer/AudioVisualizer.module.scss"],"names":[],"mappings":"AACA,sBACI,UAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,MAAA,CACA,qBAAA,CACA,iBAAA,CACA,eAAA,CAEA,0BACI,UAAA,CAGJ,4CACI,UAAA,CACA,cAAA,CAGJ,4CACI,WAAA,CACA,cAAA,CAGJ,4CACI,SAAA","sourcesContent":["@import \"app/colors.scss\";\n.container {\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    flex: 1;\r\n    background-color: #ffffff; // Белый фон\r\n    position: relative;\r\n    overflow: hidden;\r\n\r\n    svg {\r\n        width: 100%;\r\n    }\r\n\r\n    .centerLine {\r\n        stroke: #ff0000; // Красная линия\r\n        stroke-width: 2;\r\n    }\r\n\r\n    .horizontalLine {\r\n        stroke: #cccccc; // Серая горизонтальная линия\r\n        stroke-width: 1;\r\n    }\r\n\r\n    .bar {\r\n        fill: #000000; // Черные полосы\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "pGeTbCcs5SZC0Qdo0ljT",
	"centerLine": "nUDihDNNGWV43U4fhGxy",
	"horizontalLine": "KilFHg8dvL7NiBhZpU87",
	"bar": "rLSUJrit1OopYPgUJZdf"
};
export default ___CSS_LOADER_EXPORT___;
