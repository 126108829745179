import Logo from "app/components/Logo";
import React from "react";
import AdminMenu from "../AdminMenu/AdminMenu";
import AdminProfile from "../AdminProfile/AdminProfile";
import styles from './Header.module.scss';

const Header: React.FC = () => {
    return <div className={styles.header}>
        <Logo size={205} />
        <AdminMenu />
        <div className={styles.profile_wrapper}>
            <AdminProfile />
        </div>
    </div>;
};

export default Header;