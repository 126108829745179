// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hh0Iuo0GY8VO8JGm9m2x{height:50vh;display:flex;justify-content:center;align-items:center}.vxAyPbvtRWCESbPOoq_J{text-align:center}.YavtmTPOUF5HYTdZY14Q{display:inline-block;margin-top:5px;padding:10px 20px;background-color:#3a302e;border:1px solid #ccc;border-radius:10px;text-decoration:none;color:#fff;cursor:pointer;transition:background-color .3s}", "",{"version":3,"sources":["webpack://./src/routes/public/NotFound/NotFound.module.scss"],"names":[],"mappings":"AACA,sBACI,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGJ,sBACI,iBAAA,CAGJ,sBACI,oBAAA,CACA,cAAA,CACA,iBAAA,CACA,wBAAA,CACA,qBAAA,CACA,kBAAA,CACA,oBAAA,CACA,UAAA,CACA,cAAA,CACA,+BAAA","sourcesContent":["@import \"app/colors.scss\";\n.wrapper {\r\n    height: 50vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.content {\r\n    text-align: center;   \r\n}\r\n\r\n.back_btn {\r\n    display: inline-block;\r\n    margin-top: 5px;\r\n    padding: 10px 20px;\r\n    background-color: #3a302e;\r\n    border: 1px solid #ccc;\r\n    border-radius: 10px;\r\n    text-decoration: none;\r\n    color: #fff;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "hh0Iuo0GY8VO8JGm9m2x",
	"content": "vxAyPbvtRWCESbPOoq_J",
	"back_btn": "YavtmTPOUF5HYTdZY14Q"
};
export default ___CSS_LOADER_EXPORT___;
