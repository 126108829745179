import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Schedule from "./components/Schedule/Schedule";
import Recording from "./components/Recording/Recording";
import { AppointmentClientDto } from "api/vs-recording/routes/Appointment/DTO/AppointmentClientDto";
import { AppointmentListStore } from "./components/Schedule/AppointmentList/AppointmentListStore";
import { observer, useLocalObservable } from "mobx-react";
import useApi from "misc/hooks/useApi";
import { Breadcrumb, ConfigProvider, ThemeConfig } from "antd";
import styles from "./VSRecording.module.scss";
import useCurrentUser from "misc/hooks/useCurrentUser";
import { UserRoleEnum } from "api/model/User/UserRole";
import { COLORS } from "./styles/Colors";
import { AppointmentFeedbackStatus } from "api/vs-recording/model/Appointments/enums/AppointmentFeedbackStatus";

const VSRecording: React.FC = () => {
    const api = useApi();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [appointment, setAppointment] = useState<AppointmentClientDto | null>(null);
    const store = useLocalObservable<AppointmentListStore>(() => new AppointmentListStore(api));
    const appWrapperRef = useRef<HTMLDivElement>(null);
    const user = useCurrentUser();

    const theme = useMemo(() => ({
        components: {
            Typography: {
                colorTextDisabled: COLORS.disabledBG
            },
            Button: {
                defaultBg: COLORS.buttonBg,
                colorText: COLORS.white,
                colorBorder: COLORS.buttonBg,
                defaultHoverBorderColor: COLORS.buttonBgHover,
                defaultHoverBg: COLORS.buttonBgHover,
                defaultHoverColor: COLORS.white,
                colorTextDisabled: COLORS.grey,
                borderColorDisabled: COLORS.lightGrey,
                controlItemBgActiveDisabled: COLORS.lightGrey,
                colorLink: COLORS.orange,
                colorLinkHover: COLORS.buttonBgHover,
                borderRadius: '100px !important' as any

            },
            Radio: {
                buttonSolidCheckedBg: COLORS.buttonBg,
                buttonSolidCheckedHoverBg: COLORS.buttonBgHover,
                buttonSolidCheckedColor: COLORS.white,
                buttonBg: COLORS.white,
                buttonColor: COLORS.grey,
                colorBorder: COLORS.grey,
                colorPrimaryActive: COLORS.buttonBg,
                colorInfoTextHover: COLORS.grey,
                colorPrimary: COLORS.grey,
                colorTextDisabled: COLORS.lightGrey,
                buttonCheckedColorDisabled: COLORS.lightGrey,
                buttonCheckedBgDisabled: COLORS.grey,
            },
            Select: {
                colorTextDisabled: COLORS.lightGrey,
            },
            Progress: {
                circleIconFontSize: "12px",
                defaultColor: COLORS.buttonBg,
                colorSuccess: COLORS.buttonBg
            },
            Modal: {
                colorPrimary: COLORS.buttonBg,
                colorPrimaryHover: COLORS.buttonBgHover,
                colorPrimaryActive: COLORS.buttonBg,
            },
            Tabs: {
                itemColor: COLORS.orange,
                itemActiveColor: COLORS.orange,
                itemSelectedColor: COLORS.orange,
                inkBarColor: COLORS.orange,
                itemHoverColor: COLORS.buttonBgHover,
            }
        }
    } as ThemeConfig), []);

    const activeTab = useMemo(() => (id ? "recording" : "schedule"), [id]);

    useEffect(() => {
        if (activeTab === "schedule") setAppointment(null);
    }, [activeTab]);

    const goToRecording = useCallback((a: AppointmentClientDto) => {
        navigate(`/rec/appointment/${a?.id}`);
        setAppointment(a);
    }, [navigate]);

    const showApp = useMemo(() => {
        if (user.isInRole([UserRoleEnum.VetTech]) || user.isInRole([UserRoleEnum.AccountVetTech])) {
            if (user.isSAASUser) return true;
            return user.practiceIds.length > 0 || user.doctorIds?.length > 0;
        }
        return user.doctorIds?.length > 0;
    }, [user]);
    return <ConfigProvider theme={theme} getPopupContainer={() => appWrapperRef.current}>
        <div className={styles.app_wrapper} ref={appWrapperRef}>
            {!showApp
                ? <h3 className="color-black text-center">
                    {!user.isSAASUser && user.practiceIds.length === 0
                        ? "There are no practices assigned to your account. Please contact your administrator."
                        : "You don't have any doctor assigned to your account. Please contact support"}
                </h3>
                : activeTab === "schedule"
                    ? <Schedule store={store} onAppointmentClick={x => goToRecording(x)} />
                    : <Recording
                        key={id}
                        appointmentId={Number(id)}
                        listStore={store}
                        onDeleteOrBack={async () => {
                            navigate("/rec/schedule");
                            setTimeout(async () => {
                                await store.load();
                            }, 100);
                        }}
                    />}
        </div>
    </ConfigProvider>;
};

export default observer(VSRecording);
