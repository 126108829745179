import React, { useContext, useMemo, useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { AppointmentFeedbackNote } from 'api/vs-recording/model/Appointments/AppointmentFeedbackNote';
import { AppointmentFeedbackAudio } from 'api/vs-recording/model/Audio/AppointmentFeedbackAudio';
import { AppointmentFeedbackClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import OneNote from './components/OneNote/OneNote';
import { TemplateFieldType } from 'api/model/Template/TemplateField';
import styles from './SubmitFeedback.module.scss';
import { COLORS } from 'vs-recording/styles/Colors';

const SubmitFeedbackNotes: React.FC<{ feedback: AppointmentFeedbackClientDto }> = observer(({ feedback }) => {
    const appointmentContext = useContext(AppointmentStoreContext);
    const customNotesRef = useRef<HTMLDivElement>(null);

    const addNewNote = async () => {
        await appointmentContext!.addNewNote();
        customNotesRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const saveNote = async (note: AppointmentFeedbackNote) => {
        if (!appointmentContext) return;
        await appointmentContext.saveNote(note);
    };

    const onDeleteAudio = async (audio: AppointmentFeedbackAudio, note: AppointmentFeedbackNote) => {
        await appointmentContext!.deleteAudioFromNote(audio, note);
    };

    const renderNotes = (notes: AppointmentFeedbackNote[], removable: boolean) => {
        const hasSeparators = notes.some(note => note.type === TemplateFieldType.Separator);

        if (hasSeparators) {
            const noteGroups: { group: string; items: AppointmentFeedbackNote[] }[] = [];
            let currentGroup: { group: string; items: AppointmentFeedbackNote[] } | null = null;

            notes.forEach(note => {
                if (note.type === TemplateFieldType.Separator) {
                    if (currentGroup && currentGroup.items.length > 0) {
                        noteGroups.push(currentGroup);
                    }
                    currentGroup = { group: note.name || 'Unnamed Group', items: [] };
                } else {
                    if (!currentGroup) {
                        currentGroup = { group: 'Default Group', items: [] };
                    }
                    currentGroup.items.push(note);
                }
            });

            if (currentGroup && currentGroup.items.length > 0) {
                noteGroups.push(currentGroup);
            }

            return noteGroups.map((group, groupIndex) => (
                <div key={groupIndex} className={styles.dictationNoteSection} style={{ marginTop: group.group ? 40 : 24 }}>
                    <div className={styles.dictationNoteSectionTitle} >{group.group || `Group #${groupIndex + 1}`}</div>
                    {group.items.map((note, noteIndex) => {
                        const defaultName = `Note #${noteIndex + 1}`;
                        const noteName = note.name && note.name.length > 0 ? note.name : note.templateFieldId ? null : defaultName;

                        return <OneNote
                            currentNote={note}
                            type={note.type}
                            removable={removable}
                            name={noteName}
                            key={`${note.id}|${noteIndex}|${note.createdDate}`}
                            value={note.text || ''}
                            onChange={(t) => runInAction(() => {
                                note.needToSave = true;
                                note.text = t;
                            })}
                            onDelete={() => appointmentContext!.deleteNote(note)}
                            saveNote={() => saveNote(note)}
                            loading={appointmentContext!.savingNote}
                            audios={note.appointmentFeedbackAudio || []}
                            onDeleteAudio={(audio: AppointmentFeedbackAudio) => onDeleteAudio(audio, note)}
                        />;
                    })}
                </div>
            ));
        } else {
            return <div style={{ background: COLORS.modalBg, padding: 8, borderRadius: 25 }}>
                {notes.map((note, noteIndex) => {
                    const defaultName = `Note #${noteIndex + 1}`;
                    const noteName = note.name && note.name.length > 0 ? note.name : note.templateFieldId ? null : defaultName;

                    return <OneNote
                        currentNote={note}
                        type={note.type}
                        removable={removable}
                        name={noteName}
                        key={`${note.id}|${noteIndex}|${note.createdDate}`}
                        value={note.text || ''}
                        onChange={(t) => runInAction(() => {
                            note.needToSave = true;
                            note.text = t;
                        })}
                        onDelete={() => appointmentContext!.deleteNote(note)}
                        saveNote={() => saveNote(note)}
                        loading={appointmentContext!.savingNote}
                        audios={note.appointmentFeedbackAudio || []}
                        onDeleteAudio={(audio: AppointmentFeedbackAudio) => onDeleteAudio(audio, note)}
                    />;
                })}
            </div>;
        }
    };

    const templateFieldNotes = useMemo(() =>
        feedback.notes
            .filter(x => x.templateFieldId != null)
            .sort((a, b) => (a.position || 0) - (b.position || 0))
        , [feedback.notes]);

    const customNotes = useMemo(() =>
        feedback.notes
            .filter(x => x.templateFieldId == null && x.isAmbient == false)
            .sort((a, b) => a.createdDate == null || b.createdDate == null ? 1 :
                new Date(a.createdDate).getTime() > new Date(b.createdDate).getTime() ? 1 : -1)
        , [feedback.notes]);

    return <div className='flex-1 mt-20 w-100'>
        <div className={styles.dictationAudiosScroll}>
            {renderNotes(templateFieldNotes, false)}
            {customNotes.length > 0 && <div className={styles.dictationNoteSection}>
                <div className={styles.dictationNoteSectionTitle} ref={customNotesRef}>Custom Notes</div>
                {renderNotes(customNotes, true)}
            </div>}
        </div>
        <div className='w-100 flex justify-center mt-10'>
            <Button onClick={addNewNote} icon={<PlusOutlined />}>
                Add Custom Note
            </Button>
        </div>
    </div>;
});

export default SubmitFeedbackNotes;
