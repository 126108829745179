import { OfficeUpdateRequest } from "api/routes/Offices/Office/Model/OfficeUpdateRequest";
import { UserUpdateRequest } from "api/routes/UserAdmin/Model/UserUpdateRequest";
import { Office } from "api/model/Offices/Office";
import { User } from "api/model/User/User";
import { UserRoleEnum, UserRolesList } from "api/model/User/UserRole";
import { Alert, Input, Button, Form, Divider, notification, Row, Select, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import DoctorSelect from "app/components/DoctorSelect";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import React, { useState } from "react";


const OfficeEdit: React.FC<{ office: Office, onSuccess: () => void, onCancel: () => void }> = ({ office, onSuccess, onCancel }) => {
	const api = useApi();
	const currentUser = useCurrentUser();
	const [form] = useForm();
	const [state, setState] = useState<{ error: string | null, loading: boolean }>({ error: null, loading: false });

	const submit = async (values: OfficeUpdateRequest) => {
		setState({ ...state, loading: true, error: "" })

		try {
			values.id = office.id;
			const res = await api.adminOffice.updateCreate(values);
			if (res.success == true) {
				notification.success({ message: "Saved" })
				setState({ ...state, error: null, loading: false })
				if (onSuccess) onSuccess()
			} else {
				setState({ ...state, loading: false, error: res.error || "Error occured" })
			}

		} catch (e: any) {
			setState({ ...state, error: e.message, loading: false })
		}
	}

	return <Form form={form} onFinish={submit} layout="vertical" initialValues={office || {}}  >
		{state.error && <Alert style={{ marginBottom: 10 }} type="warning" message={state.error} />}

		<Form.Item label={"Name"} name="name">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Address 1"} name="addressLine1">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Address 2"} name="addressLine2">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"City"} name="city">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"State"} name="state">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Phone"} name="phone">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Email"} name="email">
			<Input type="text" />
		</Form.Item>


		<Form.Item label={"Website"} name="website">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Zip"} name="zip">
			<Input type="text" />
		</Form.Item>


		<Divider />

		<Row justify="space-between">
			<Button onClick={onCancel} loading={state.loading}>{"Cancel"}</Button>
			<Button type="primary" htmlType="submit" loading={state.loading}>{"Save"}</Button>
		</Row>
	</Form>
}

export default OfficeEdit;
