import React, { useEffect, useRef, useState } from 'react';
import styles from './MicActivityIndicator.module.scss';
import { COLORS } from 'vs-recording/styles/Colors';

type MicActivityIndicatorProps = {
    stream: MediaStream | null;
    width?: number; // Ширина индикатора
    height?: number; // Высота индикатора
};

const MicActivityIndicator: React.FC<MicActivityIndicatorProps> = ({ stream, width = 100, height = 50 }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);
    const barCount = Math.floor(width / 2); // Количество полос (ширина каждой полосы + отступ)
    const barWidth = 1; // Ширина каждой полосы
    const barHeightMultiplier = height * 1.2; // Максимальная высота полосы
    const animationRef = useRef<number | null>(null); // Для хранения ID анимации

    useEffect(() => {
        const audioContext = new (window.AudioContext)();
        const analyserNode = audioContext.createAnalyser();
        analyserNode.fftSize = 2048; // Размер FFT

        if (stream) {
            const microphone = audioContext.createMediaStreamSource(stream);
            microphone.connect(analyserNode);
            setAnalyser(analyserNode);
            startDrawing(analyserNode);
        }

        return () => {
            if (analyser) {
                analyser.disconnect();
            }
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
            audioContext.close(); // Закрываем AudioContext при размонтировании
        };
    }, [stream]);

    const startDrawing = (analyserNode: AnalyserNode) => {
        const draw = () => {
            if (!analyserNode || !canvasRef.current) return;

            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            const dataArray = new Uint8Array(analyserNode.frequencyBinCount);
            analyserNode.getByteFrequencyData(dataArray); // Получаем данные о частотах

            ctx.clearRect(0, 0, canvas.width, canvas.height); // Очищаем канвас
            ctx.fillStyle = COLORS.orange; // Цвет полос

            for (let i = 0; i < barCount; i++) {
                const barHeight = (dataArray[i] / 255) * barHeightMultiplier; // Нормализация высоты полосы
                const x = i * (barWidth + 2); // Позиция по оси X

                ctx.fillRect(x, height - barHeight, barWidth, barHeight); // Рисуем полосу
            }

            animationRef.current = requestAnimationFrame(() => draw()); // Запрашиваем следующую анимацию
        };

        draw(); // Начинаем рисовать
    };

    return (
        <canvas
            ref={canvasRef}
            width={width}
            height={height}
            className={styles.micActivityIndicator}
        />
    );
};

export default MicActivityIndicator;