import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { Button, notification, Typography, Select, Dropdown, Menu, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import AudioFileStorage from '../../../../stores/AudioFileStorage';
import styles from './AudioRecorder.module.scss';
import MicActivityIndicator from '../MicActivityIndicator/MicActivityIndicator';
import { AudioFilled, XFilled } from '@ant-design/icons';
import fixWebmDuration from 'webm-duration-fix';
import AudioVisualizer from '../AudioVisualizer/AudioVisualizer';

type AudioRecorderProps = {
    noteId: number;
    feedbackId: number;
    recorderRef?: React.MutableRefObject<{ startRecording: () => void; stopRecording: () => void; isRecording: boolean } | null>;
    onRecorded?: (uri: string, duration: number) => void;
    onStartRecording?: () => void;
    onStopRecording?: () => void;
    onError?: (error: any) => void;
    onUpdateRecording?: (uri: string, duration: number) => void;
    mode?: 'modal' | 'default';
};

const AudioRecorder: React.FC<AudioRecorderProps> = ({
    noteId,
    recorderRef,
    feedbackId,
    onRecorded,
    onStartRecording,
    onStopRecording,
    onError,
    onUpdateRecording,
    mode = 'default',
}) => {
    const mediaRecorder = useRef<MediaRecorder | null>(null);
    const chunks = useRef<Blob[]>([]);
    const durationRef = useRef<number>(-1);
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [currentDuration, setCurrentDuration] = useState<number>(0);
    const recordingLimitMinutes = 120;
    const recordingLimitMillis = recordingLimitMinutes * 60 * 1000;
    const startTime = useRef<number | null>(null);
    const updateInterval = useRef<number | null>(null);
    const audioKeyRef = useRef<string>('');
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');
    const [stream, setStream] = useState<MediaStream | null>(null);
    const blobSlice: BlobPart[] = [];

    const isSafari = useMemo(() => /^((?!chrome|android).)*safari/i.test(navigator.userAgent), [navigator.userAgent]);

    useEffect(() => {
        return () => {
            if (stream) {
                stream.getTracks().forEach(t => t.stop())
            }
        }
    }, [stream]);

    const getDevices = useCallback(async () => {
        const deviceInfos = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = deviceInfos.filter(device => device.kind === 'audioinput');
        setDevices(audioDevices);
        const savedDeviceId = localStorage.getItem('selectedDeviceId');
        if (savedDeviceId && audioDevices.some(device => device.deviceId === savedDeviceId)) {
            setSelectedDeviceId(savedDeviceId);
        } else if (audioDevices.length > 0) {
            setSelectedDeviceId(audioDevices[0].deviceId);
        }
    }, []);

    useEffect(() => {
        getDevices();

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isRecording) {
                const confirmationMessage = 'You have unsaved changes, do you really want to leave?';
                event.preventDefault();
                event.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        const handleUnload = async () => {
            if (isRecording) {
                await stopRecording();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', handleUnload);

        return () => {
            stopRecording();
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
        };
    }, [isRecording]);

    useEffect(() => {
        if (selectedDeviceId) {
            localStorage.setItem('selectedDeviceId', selectedDeviceId);
            startAudioStream();
        }
    }, [selectedDeviceId]);

    useEffect(() => {
        const checkMicrophonePermission = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                setStream(stream);
                getDevices();
            } catch (error) {
                if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                    notification.error({
                        message: 'Permission Denied',
                        description: 'Microphone access was denied. Please enable it in your browser settings.',
                    });
                } else {
                    notification.error({
                        message: 'Error',
                        description: error.message,
                    });
                }
            }
        };

        checkMicrophonePermission();
    }, []);

    const startAudioStream = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            notification.error({
                message: 'Error',
                description: 'Media devices not supported.',
            });
            return;
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: selectedDeviceId } });
            setStream(stream);
        } catch (error) {
            if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                notification.error({
                    message: 'Permission Denied',
                    description: 'Microphone access was denied. Please enable it in your browser settings.',
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: error.message,
                });
            }
        }
    };

    const startRecording = async () => {
        if (isRecording) return;

        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            notification.error({
                message: 'Error',
                description: 'Media devices not supported.',
            });
            return;
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: selectedDeviceId } });
            const mimeType = isSafari ? 'audio/mp4' : 'audio/webm';

            // optimal bitrate 96kbps
            const audioBitsPerSecond = 96000;

            mediaRecorder.current = new MediaRecorder(stream, { mimeType, audioBitsPerSecond });
            console.log('MediaRecorder started', mimeType, 'with audioBitsPerSecond:', audioBitsPerSecond);

            mediaRecorder.current.ondataavailable = async (e) => {
                if (e.data.size > 0) {
                    chunks.current.push(e.data);
                    blobSlice.push(e.data);

                    const blob = new Blob(chunks.current, { type: mimeType });

                    if (blob.size > 0) {
                        await AudioFileStorage.saveByKey(audioKeyRef.current, {
                            blob,
                            uri: audioKeyRef.current,
                            isInProgress: true,
                            appointmentFeedBackNoteId: noteId,
                            appointmentFeedBackId: feedbackId,
                        });
                    }
                }
            };

            mediaRecorder.current.onstop = async () => {
                chunks.current = [];

                let fixedBlob;
                if (isSafari) {
                    fixedBlob = new Blob([...blobSlice], { type: 'audio/mp4' });
                } else {
                    // Fix duration of the blob for non-Safari browsers
                    fixedBlob = await fixWebmDuration(new Blob([...blobSlice], { type: 'audio/webm' }));
                }

                await AudioFileStorage.saveByKey(audioKeyRef.current, {
                    blob: fixedBlob,
                    uri: audioKeyRef.current,
                    isInProgress: false,
                    appointmentFeedBackNoteId: noteId,
                    appointmentFeedBackId: feedbackId,
                });

                if (audioKeyRef.current) onRecorded?.(audioKeyRef.current, durationRef.current);

                if (onStopRecording) onStopRecording();

                setIsRecording(false);
                durationRef.current = -1;
                setCurrentDuration(0);

                await AudioFileStorage.deleteTemporaryBlobs(noteId);
                blobSlice.length = 0;
            };

            audioKeyRef.current = AudioFileStorage.feedbackNoteKey(noteId, Date.now());

            await AudioFileStorage.saveByKey(audioKeyRef.current, {
                blob: null,
                uri: audioKeyRef.current,
                isInProgress: true,
                appointmentFeedBackNoteId: noteId,
                appointmentFeedBackId: feedbackId,
            });

            mediaRecorder.current.start(1000);

            if (onStartRecording) onStartRecording();

            setIsRecording(true);
            startTime.current = Date.now();
            updateInterval.current = window.setInterval(updateRecordingStatus, 1000);
        } catch (err) {
            console.error('Failed to start recording', err);
            if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
                notification.error({
                    message: 'Permission Denied',
                    description: 'Microphone access was denied. Please enable it in your browser settings.',
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: err.message,
                });
            }
            if (onError) onError(err);
        }
    };

    useEffect(() => {
        if (recorderRef) {
            recorderRef.current = {
                startRecording,
                stopRecording,
                isRecording,
            };
        }
    }, [isRecording]);

    const stopRecording = async () => {
        if (mediaRecorder.current && isRecording) {
            clearInterval(updateInterval.current);
            mediaRecorder.current.stop();
        }
    };

    const updateRecordingStatus = async () => {
        if (!startTime.current) return;
        const elapsedMillis = Date.now() - startTime.current;
        durationRef.current = Math.round(elapsedMillis / 1000);
        setCurrentDuration(durationRef.current);

        mediaRecorder.current?.requestData();

        onUpdateRecording?.(audioKeyRef.current, durationRef.current);

        if (elapsedMillis > recordingLimitMillis) {
            stopRecording();
        }
    };

    const getFormattedTime = (seconds: number) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = seconds % 60;
        return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
    };

    const handleMenuClick = ({ key }: { key: string }) => {
        setSelectedDeviceId(key);
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            {devices.map((device) => (
                <Menu.Item key={device.deviceId}>
                    {device.label || `Microphone ${device.deviceId}`}
                </Menu.Item>
            ))}
        </Menu>
    );

    const selectedDeviceLabel = devices.find(device => device.deviceId === selectedDeviceId)?.label || `Microphone ${selectedDeviceId}`;

    return <div className={isRecording ? styles.recordingContainer : ''} data-mode={mode}>
        {isRecording && <div className='flex flex-col'>
            <div className='flex flex-col align-center justify-between color-grey'>
                <span>Recording in progress,</span>
                <span>Maximum {recordingLimitMinutes} minutes for one recording</span>
                <span className='color-black fs-60'>{getFormattedTime(currentDuration)}</span>
            </div>
        </div>}
        {isRecording && <AudioVisualizer active stream={stream} />}

        <div className={styles.recBtn} data-recording={isRecording} onClick={!isRecording ? startRecording : stopRecording}>
            <div className={styles.recIconWrapper}>
                {isRecording ? <XFilled className={styles.recIcon} /> : <AudioFilled className={styles.recIcon} />}
            </div>
            <span className={styles.recText}>{isRecording ? "Stop recording" : "Start recording"}</span>
            {!isRecording && <Tooltip title="This indicator shows audio wave from selected input device">
                <div style={{ position: 'absolute', right: 20, bottom: 24 }}>
                    <MicActivityIndicator stream={stream} width={60} height={20} />
                </div>
            </Tooltip>}
        </div>


        {
            !isRecording && <>
                {(devices.length > 1 && !isRecording) && <div className='mt-5 flex justify-center' onClick={e => e.stopPropagation()}>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <Button type='link' className='color-grey'>
                            <span style={{ textDecoration: 'underline', textUnderlineOffset: 3 }}>{selectedDeviceLabel}</span>
                        </Button>
                    </Dropdown>
                </div>}
            </>
        }
    </div >;
};

export default observer(AudioRecorder);
