import { CheckOutlined, WarningOutlined, CloseOutlined } from "@ant-design/icons";
import { Alert, Breadcrumb, Button, Card, Col, Divider, Drawer, Dropdown, Modal, Popconfirm, Row, Tag, Tooltip, notification } from "antd";
import { AppointmentFeedbackReviewTier, AppointmentFeedbackReviewTierList } from "api/model/Appointments/enums/AppointmentFeedbackReviewTier";
import { AppointmentFeedbackStatus, AppointmentFeedbackStatusList } from "api/model/Appointments/enums/AppointmentFeedbackStatus";
import { SAASUserRoles, UserRoleEnum } from "api/model/User/UserRole";
import { AdminFeedbackDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackDto";
import { AdminFeedbackUpdateChatDto } from "api/routes/Appointments/Feedback/DTO/AdminFeedbackUpdateChatDto";
import { tryParseInt } from "misc/helpers/intExtensions";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import useVSHub from "misc/hooks/useVSHub";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppointmentView from "routes/appointment/AppointmentView";
import FeedbackHistory from "./History/FeedbackHistory";
import MessageList from "./Messages/MessageList";
import FeedbackEdit from "./com/FeedbackEdit";
import FeedbackOutput, { FeedbackOutputTabs } from "../../app/components/FeedbackOutputView/FeedbackOutput";
import FeedbackRecordings from "./com/FeedbackRecordings";
import useDrawer from "misc/hooks/useDrawer";
import { LanguageEnum } from "api/model/User/Language";


const FeedbackView: React.FC = observer(() => {
	const { id: feedbackId } = useParams();

	const api = useApi();
	const navigate = useNavigate();

	const [state, setState] =
		useState<{ isLoading: boolean, error: string | null, feedback: AdminFeedbackDto | null }>
			({ isLoading: true, error: null, feedback: null });

	const hub = useVSHub();

	const user = useCurrentUser();
	const outputModal = useDrawer<{ id: number, tab?: FeedbackOutputTabs }>();
	const historyDrawer = useDrawer();

	const editModal = useDrawer();
	const isCurrentUserEditing = useMemo(() => state.feedback?.editorId == user.id, [state.feedback?.editorId, user.id]);

	useEffect(() => {
		loadFeedback();
	}, [feedbackId]);

	useEffect(() => {
		if (!hub.connected || !state.feedback) return;

		hub.subscribe(`admin-feedback-updates-${feedbackId}`);
		hub.on<string>(`update-feedback-${feedbackId}`, updateFeedback)
		hub.localSubscribe<string>(`update-chat`, updateFeedbackChat);


		return () => {
			hub.localUnsubscribe(`update-chat`, updateFeedbackChat);
			hub.unsubscribe(`admin-feedback-updates-${feedbackId}`);
			hub.off<string>(`update-feedback-${feedbackId}`)
		}
	}, [hub.connected, state.feedback]);

	const updateFeedback = async (fb: string) => {
		var res = JSON.parse(fb) as AdminFeedbackDto;

		if (state.feedback?.id == res.id)
			setState(prev => {
				return { feedback: { ...prev.feedback, ...res }, isLoading: false, error: null };
			});
	}

	const updateFeedbackChat = async (fbChatData: string) => {
		var res = JSON.parse(fbChatData) as AdminFeedbackUpdateChatDto;
		if (state.feedback?.id == res.id)
			setState(prev => {
				return { feedback: { ...prev.feedback, ...res }, isLoading: false, error: null };
			});
	}

	const loadFeedback = useCallback(async () => {
		setState(prevState => ({ ...prevState, isLoading: true, error: null }));
		try {
			const res = await api.adminFeedback.get(tryParseInt(feedbackId));
			if (res.success) {
				setState(prevState => ({ ...prevState, feedback: res.data, isLoading: false }));
			} else {
				notification.error({ message: res.error });
				setState(prevState => ({ ...prevState, isLoading: false }));
			}
		} catch (e: any) {
			notification.error({ message: e.error });
			setState(prevState => ({ ...prevState, isLoading: false }));
		}
	}, [feedbackId]);

	const completeEditing = useCallback(async () => {
		setState(prevState => ({ ...prevState, isLoading: true, error: null }));
		try {
			const res = await api.adminFeedback.updateStatus(state.feedback, AppointmentFeedbackStatus.MarkupDone);
			setState(prevState => ({ ...prevState, feedback: res, isLoading: false }));
		} catch (e: any) {
			notification.error({ message: e.error });
			setState(prevState => ({ ...prevState, isLoading: false }));
		}
	}, [state.feedback]);

	const completeRecording = useCallback(async () => {
		setState(prevState => ({ ...prevState, isLoading: true, error: null }));
		try {
			const res = await api.adminFeedback.updateStatus(state.feedback, AppointmentFeedbackStatus.Completed);
			setState(prevState => ({ ...prevState, feedback: res, isLoading: false }));
		} catch (e: any) {
			notification.error({ message: e.error });
			setState(prevState => ({ ...prevState, isLoading: false }));
		}
	}, [state.feedback]);


	const sendToTier = useCallback(async (tier: AppointmentFeedbackReviewTier) => {
		setState(prevState => ({ ...prevState, isLoading: true, error: null }));
		try {
			const res = await api.adminFeedback.sendToTier(state.feedback, tier);
			setState(prevState => ({ ...prevState, feedback: res, isLoading: false }));
		} catch (e: any) {
			notification.error({ message: e.error });
			setState(prevState => ({ ...prevState, isLoading: false }));
		}
	}, [state.feedback]);

	const toggleAddedToPims = useCallback(async () => {
		setState(prevState => ({ ...prevState, isLoading: true, error: null }));
		try {
			const res = await api.adminFeedback.addedToPims(state.feedback?.id, !state.feedback.addedToPims);
			setState(prevState => ({ ...prevState, feedback: res, isLoading: false }));
		} catch (e: any) {
			notification.error({ message: e.error });
			setState(prevState => ({ ...prevState, isLoading: false }));
		}
	}, [state.feedback]);

	const toggleUseForTraining = async () => {
		setState({ ...state, isLoading: true, error: null })
		try {
			var res = await api.adminFeedback.useForTraining(state.feedback?.id, !state.feedback.useForTraining);
			setState({ ...state, feedback: res, isLoading: false });
		} catch (e: any) {
			notification.error({ message: e.error });
			setState({ ...state, isLoading: false })
		}
	}

	const returnToMarkup = useCallback(async () => {
		setState(prevState => ({ ...prevState, isLoading: true, error: null }));
		try {
			const res = await api.adminFeedback.updateStatus(state.feedback, AppointmentFeedbackStatus.Markup);
			setState(prevState => ({ ...prevState, feedback: res, isLoading: false }));
		} catch (e: any) {
			notification.error({ message: e.error });
			setState(prevState => ({ ...prevState, isLoading: false }));
		}
	}, [state.feedback]);

	const deleteFeedback = useCallback(async () => {
		if (!state.feedback.id) return;
		setState(prevState => ({ ...prevState, isLoading: true, error: null }));
		try {
			await api.adminFeedback.delete(state.feedback.id);
			navigate("/recordings");
		} catch (e: any) {
			notification.error({ message: e.error });
			setState(prevState => ({ ...prevState, isLoading: false }));
		}
	}, [state.feedback]);

	const closeChat = useCallback(async () => {
		if (!state.feedback.id) return;
		setState(prevState => ({ ...prevState, isLoading: true, error: null }));
		try {
			await api.adminFeedback.closeChat(state.feedback.id);
		} catch (e: any) {
			notification.error({ message: e.error });
			setState(prevState => ({ ...prevState, isLoading: false }));
		}
	}, [state.feedback]);

	const reProcess = useCallback(async () => {
		if (!state.feedback.id) return;
		setState(prevState => ({ ...prevState, isLoading: true, error: null }));
		try {
			await api.adminFeedback.reProcess(state.feedback.id);
			notification.success({ message: 'Refresh the page' });
		} catch (e: any) {
			notification.error({ message: e.error });
			setState(prevState => ({ ...prevState, isLoading: false }));
		}
	}, [state.feedback]);

	const changeLanguage = useCallback(async (lang: LanguageEnum) => {
		if (!state.feedback.id) return;
		setState(prevState => ({ ...prevState, isLoading: true, error: null }));
		try {
			await api.adminFeedback.changeLanguage(state.feedback.id, lang);
			setState(prevState => ({ ...prevState, isLoading: false }));
		} catch (e: any) {
			notification.error({ message: e.error });
			setState(prevState => ({ ...prevState, isLoading: false }));
		}
	}, [state.feedback]);

	const setEditing = useCallback(async (isEditing: boolean) => {
		if (!state.feedback) return;

		try {
			const res = await api.adminFeedback.isEditing(state.feedback, isEditing);
			setState(prevState => ({ ...prevState, ...res }));
		} catch (e: any) {
			notification.error({ message: e.error });
		}
	}, [state.feedback]);

	const isOwner = state.feedback && user.doctorIds.includes(state.feedback.appointment.doctorId)
	const isPowerAdmin = user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.Supervisor, UserRoleEnum.Vet, ...SAASUserRoles]);

	const hasAccess = isPowerAdmin || isOwner;

	const showEditButton = state.feedback &&
		(state.feedback.status == AppointmentFeedbackStatus.Markup || state.feedback.status == AppointmentFeedbackStatus.MarkupDone) ||
		(
			state.feedback?.status == AppointmentFeedbackStatus.Completed &&
			user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.Supervisor])
		);

	const disableEditButton = state.feedback &&
		state.feedback.editorId != user.id &&
		((state.feedback.isEditing && !hasAccess) ||
			(state.feedback.reviewTier != AppointmentFeedbackReviewTier.Scribe && !hasAccess));

	const showCompleteEditingButton = state.feedback && (state.feedback.status == AppointmentFeedbackStatus.Markup) && hasAccess && !user.isSAASUser && !state.feedback.isEditing;
	let showCompleteButton = state.feedback &&
		((state.feedback.status == AppointmentFeedbackStatus.Markup && (user.isSAASUser)) || state.feedback.status == AppointmentFeedbackStatus.MarkupDone) &&
		hasAccess;


	let showReturnToMarkupButton = state.feedback &&
		(state.feedback.status == AppointmentFeedbackStatus.MarkupDone || state.feedback.status == AppointmentFeedbackStatus.Completed) && hasAccess;

	if (user.isSAASUser) {
		showReturnToMarkupButton = state.feedback && (state.feedback.status == AppointmentFeedbackStatus.Completed) && hasAccess;
	}

	const feedBackStatusMeta = AppointmentFeedbackStatusList.find(x => x.value == state.feedback?.status);
	const showChat = state.feedback && (!state.feedback.appointment.accountId && isPowerAdmin) || user.isInRole([UserRoleEnum.Scribe]);

	const canBeReprocessed = user.isSuperAdmin && state.feedback &&
		![AppointmentFeedbackStatus.ReadyToProcess, AppointmentFeedbackStatus.Processing, AppointmentFeedbackStatus.ErrorProcessing].includes(state.feedback.status);

	const cardContent = () => {
		const canManageChat = user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.Supervisor, UserRoleEnum.Scribe]);

		if (state.error) {
			<Alert message={state.error} />
		}

		if (state.feedback)
			return <Row gutter={[10, 10]}>
				<Col xl={12} lg={24}>
					<Card title="Appointment Information" style={{ marginBottom: "10px" }} >
						<AppointmentView appointment={state.feedback.appointment} onLangChange={lang => changeLanguage(lang)} />
					</Card>

					<Card title="Recordings" extra={<>
						{state.feedback.ambientMode && <Tag color="blue">AMBIENT MODE</Tag>}
						<Tooltip title={feedBackStatusMeta.tooltip} placement="left">
							<Tag color={feedBackStatusMeta.color}>{feedBackStatusMeta.label.toUpperCase()}</Tag>
						</Tooltip>
						<Button type='default' size={"small"} onClick={() => historyDrawer.open()}>History</Button>
						{canBeReprocessed &&
							<Popconfirm title="Are you sure you want to re-process this recording? All your edits will be removed." onConfirm={reProcess}>
								<Button type={"default"} size={"small"} className='ml-5'>Re-process</Button>
							</Popconfirm>
						}
					</>
					}>
						<FeedbackRecordings feedback={state.feedback} />
					</Card>
				</Col>
				{showChat &&
					<Col xl={12} lg={24}>
						<Card title="Chat" extra={
							state.feedback.chatOpen
								? <>
									<Tag color='blue'>OPEN</Tag>
									{canManageChat &&
										<Popconfirm
											title={"Are you sure you want to close the chat? Doctor will be able to open it"}
											okText={"Close"}
											onConfirm={closeChat}>
											<Button type={"primary"}>Close Chat</Button>
										</Popconfirm>
									}
								</>
								: <>
									<Tag color='default'>CLOSED</Tag>
								</>
						}>
							<MessageList feedback={state.feedback} />
						</Card>
					</Col>
				}
			</Row >
	}

	const buttons = () => {
		if (!state.feedback) return null;
		return <div className="feedback-btns" id="feedback-btns-container">
			{!user.isSAASUser && !user.isRestrictedUser && ReviewStageBtn()}

			<Button type={"default"} onClick={() => historyDrawer.open()}>History</Button>

			{showEditButton && <>
				{state.feedback.isEditing
					? !isCurrentUserEditing
						? <Button disabled={disableEditButton}
							type={"primary"}
							icon={<CheckOutlined />}
							onClick={() => {
								editModal.open(true);
							}}>Editing by {state.feedback?.editorName} (View)</Button>
						: <Button disabled={disableEditButton}
							type={"primary"}
							icon={<WarningOutlined />}
							onClick={() => {
								setEditing(true);
								editModal.open(false);
							}}>You are editing now</Button>
					: <Button disabled={disableEditButton}
						type={"primary"}
						onClick={() => {
							setEditing(true);
							editModal.open(false);
						}
						}>Edit Recording</Button>
				}

			</>}

			{showCompleteEditingButton &&
				<Popconfirm title="Are you sure?" onConfirm={completeEditing}>
					<Button type={"primary"}>Complete Editing</Button>
				</Popconfirm>}

			{showCompleteButton &&
				<Popconfirm title="Are you sure?" onConfirm={completeRecording}>
					<Button type={"primary"}>Complete</Button>
				</Popconfirm>}


			{showReturnToMarkupButton && <Popconfirm title="Are you sure?" onConfirm={returnToMarkup}>
				<Button type={"primary"}>Return to Editing</Button>
			</Popconfirm>}

			<Button type={"primary"} onClick={() => outputModal.open({ id: state.feedback?.id })}>Output</Button>
			<Button type={"primary"} onClick={() => outputModal.open({ id: state.feedback?.id, tab: 'visitSummary' })}>Summary</Button>

			<Popconfirm title={!state.feedback.addedToPims ? "Are you sure?" : "Remove from PIMS?"} onConfirm={toggleAddedToPims}>
				{state.feedback.addedToPims ?
					<Button type={"primary"} icon={<CheckOutlined />}>Remove from PIMS</Button> :
					<Button type={"default"} icon={<CheckOutlined />}>Add to PIMS</Button>
				}
			</Popconfirm>

			{user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.Vet]) && <Popconfirm title={"Are you sure?"} onConfirm={toggleUseForTraining}>
				{state.feedback.useForTraining
					? <Button type={"primary"} icon={<CloseOutlined />}>Do not use for training</Button>
					: <Button type={"default"} icon={<CheckOutlined />}>Use for training</Button>
				}
			</Popconfirm>}


			{user.isInRole([UserRoleEnum.SuperAdmin, UserRoleEnum.AccountManager, UserRoleEnum.AccountOwner]) &&
				<Popconfirm title={"Are you sure you want to remove this recording? This action cannot be undone."} onConfirm={deleteFeedback}>
					<Button type={"primary"} danger >Delete Recording</Button>
				</Popconfirm>
			}
		</div>
	}

	const ReviewStageBtn = () => {
		const currentStage = state.feedback.reviewTier;
		const { label, color } = AppointmentFeedbackReviewTierList.find(x => x.value == currentStage);

		const items = [
			{
				disabled: currentStage == AppointmentFeedbackReviewTier.Scribe,
				key: AppointmentFeedbackReviewTier.Scribe.toString(),
				label: <a onClick={() => sendToTier(AppointmentFeedbackReviewTier.Scribe)}> To Scriber </a>
			},
			{
				disabled: currentStage == AppointmentFeedbackReviewTier.Supervisor,
				key: AppointmentFeedbackReviewTier.Supervisor.toString(),
				label: <a onClick={() => sendToTier(AppointmentFeedbackReviewTier.Supervisor)}> To Supervisor </a>
			},

		];


		if (user.isInRole([UserRoleEnum.Supervisor, UserRoleEnum.SuperAdmin, UserRoleEnum.Vet])) {
			items.push({
				disabled: currentStage == AppointmentFeedbackReviewTier.Vet,
				key: AppointmentFeedbackReviewTier.Vet.toString(),
				label: <a onClick={() => sendToTier(AppointmentFeedbackReviewTier.Vet)}> To Vet </a>
			})
		}


		return <Dropdown overlayStyle={{ width: 200 }} placement="top" arrow menu={{ items }} trigger={["click"]} getPopupContainer={trigger => trigger.parentElement}>
			<Button loading={state.isLoading}>
				@<b>{label}</b>
				<div className={`dot ${color} ml-10`} />
			</Button>
		</Dropdown >

	}

	return <>
		<Breadcrumb>
			<Breadcrumb.Item>
				<Link to="/recordings" >Recordings</Link>
			</Breadcrumb.Item>
			<Breadcrumb.Item>
				{state.feedback && moment(state.feedback.createdDate).format("DD MMM yyyy hh:mma")}
				<Divider type="vertical" />
				{state.feedback && "Doctor: " + state.feedback.appointment?.doctorName}
			</Breadcrumb.Item>
		</Breadcrumb>
		<Divider />
		{cardContent()}
		{buttons()}

		{showEditButton ?
			<Modal title={"Editing of recording"}
				footer={false}
				styles={{ body: { padding: 0 } }}
				width={1000}
				onCancel={() => {
					if (isCurrentUserEditing)
						setEditing(false);
					editModal.close();
				}}
				open={editModal.visible}
				destroyOnClose={true}
				closable={true}
				maskClosable={false}>
				<FeedbackEdit
					feedbackId={state.feedback.id}
					onClose={() => {
						if (isCurrentUserEditing)
							setEditing(false);
						editModal.close();
					}} />
			</Modal> : null}

		{state.feedback?.id && <Drawer width={1000}
			onClose={() => historyDrawer.close()}
			title={"History"}
			open={historyDrawer.visible}
			destroyOnClose={true}
			closable={true}
			maskClosable={true}>
			<FeedbackHistory feedbackId={state.feedback.id} />
		</Drawer>}

		<Modal open={outputModal.visible}
			width={850}
			onOk={() => outputModal.close()}
			onCancel={() => outputModal.close()}
			title={"Output"}
			okText="Close"
			cancelButtonProps={{ style: { display: 'none' } }}
			destroyOnClose={true}
			closable={true}
			maskClosable={false}>
			{<FeedbackOutput id={outputModal.data?.id} tab={outputModal.data?.tab} />}
		</Modal>
	</>
})


export default FeedbackView;


