import { DeleteOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Radio, Tooltip, message, notification } from 'antd';
import { AppointmentFeedbackClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useState } from 'react';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import SubmitFeedbackAmbient from './SubmitFeedbackAmbient';
import SubmitFeedbackNotes from './SubmitFeedbackNotes';
import useCurrentUser from 'misc/hooks/useCurrentUser';
import { COLORS } from 'vs-recording/styles/Colors';
import styles from './SubmitFeedback.module.scss';
import FadeIn from 'app/components/FadeIn/FadeIn';

type SubmitFeedbackProps = {
    onDelete: () => void;
    onBack: () => void;
};

const SubmitFeedback: React.FC<SubmitFeedbackProps> = observer(({ onDelete, onBack }) => {
    const appointmentContext = useContext(AppointmentStoreContext);
    const user = useCurrentUser();
    const [feedback] = useState<AppointmentFeedbackClientDto | null>(appointmentContext?.appointment?.feedback);

    const complete = useCallback(async () => {
        await appointmentContext.completeFeedback();
    }, []);

    const saveAppointmentWithAudios = useCallback(async () => {
        const res = await appointmentContext.saveAppintmentWithAudios();
        if (res)
            notification.success({ message: 'Appointment saved successfully.', duration: 2 });
        else
            notification.error({ message: 'Error occured while saving appointment.', duration: 2 });


    }, []);

    const confirmPrecheck = useCallback(async () => {
        if (appointmentContext?.isEmpty) {
            message.warning('No recordings. Please go back and re-record.');
        } else if (appointmentContext?.isRecording) {
            message.warning('Recording in progress. Please stop recording before submit.');
        } else {
            await complete();
        }
    }, [appointmentContext, complete]);

    const setAmbientMode = useCallback(async (isAmbient: boolean) => {
        if (appointmentContext)
            await appointmentContext.setAmbientMode(isAmbient);

    }, [appointmentContext, feedback]);

    return <FadeIn>
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className='flex align-center justify-center w-100' >
                    <div style={{ background: COLORS.modalBg, borderRadius: 100 }} className='p-4'>
                        <Radio.Group
                            style={{ display: 'flex', background: COLORS.white, borderRadius: 100 }}
                            size='large'
                            buttonStyle='solid'
                            defaultValue={feedback?.ambientMode ? 'ambient' : 'dictation'}
                            value={feedback?.ambientMode ? 'ambient' : 'dictation'}
                            onChange={async (e) => {
                                await setAmbientMode(e.target.value === 'ambient');
                            }}
                            disabled={appointmentContext?.isRecording}>
                            <Radio.Button value="ambient" style={{ flex: 1, textAlign: 'center', padding: '0 40px', fontSize: 18, border: 'none', borderRadius: 100 }}>Ambient</Radio.Button>
                            <Radio.Button value="dictation" style={{ flex: 1, textAlign: 'center', padding: '0 40px', fontSize: 18, border: 'none', borderRadius: 100 }}>Dictation</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>

                {appointmentContext?.appointment?.feedback?.ambientMode === false
                    ? <SubmitFeedbackNotes feedback={feedback} />
                    : <SubmitFeedbackAmbient feedback={feedback} />}

            </div>
            <div className={styles.footer}>
                <Button size='large' icon={<ArrowLeftOutlined />}
                    onClick={onBack}>
                    Back
                </Button>
                <div className='flex'>
                    {(appointmentContext?.canBeDeleted && !appointmentContext?.isRecording) &&
                        <Tooltip title='Delete Appointment' placement='top'>
                            <Popconfirm okType='default'
                                title="Are you sure you want to delete this appointment?"
                                onConfirm={onDelete}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    size='large'
                                    icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    }
                </div>

                {user.isVetTech
                    ? <Popconfirm okType='default'
                        title="Are you sure you want to submit?"
                        onConfirm={saveAppointmentWithAudios}
                        okText="Yes"
                        cancelText="No"
                        disabled={appointmentContext?.isRecording}>
                        <Button
                            size='large'
                            disabled={appointmentContext?.isRecording}
                            loading={appointmentContext?.savingDraft || appointmentContext?.savingNote}>
                            Save
                        </Button>
                    </Popconfirm>
                    : <Button
                        size='large'
                        onClick={confirmPrecheck}
                        disabled={appointmentContext?.isRecording}
                        loading={appointmentContext?.savingDraft || appointmentContext?.savingNote}>
                        Submit
                        <ArrowRightOutlined />
                    </Button>}
            </div>
        </div >
    </FadeIn>;
});

export default SubmitFeedback;
