import useGeneralState from 'misc/hooks/useGeneralState';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import useDevice from 'misc/hooks/useDevice';
import { LiveChatWidget, EventHandlerPayload } from '@livechat/widget-react'
import { AppSettings } from 'appSettings';
import styles from './PublicLayout.module.scss';


const PublicLayout: React.FC = observer(() => {
    const gs = useGeneralState();
    const { device } = useDevice();

    return <>
        <ConfigProvider
            theme={{
                components: {
                    Form: {
                        colorTextBase: `#fff`,
                        colorTextDescription: `#fff`,
                        colorPrimaryText: `#fff`,
                        colorTextLabel: `#262626`,
                        labelColor: `#262626 !important`,
                        itemMarginBottom: device.isMobile ? 10 : 24,
                        verticalLabelPadding: device.isMobile && `0 0 0 0`
                    },
                    Button: {
                        colorPrimary: `#ef6e27`,
                        colorPrimaryHover: `#c4581e`,
                        colorPrimaryActive: `#c4581e`,
                        // @ts-ignore
                        borderRadius: '50px !important',
                    },
                    Steps: {
                        navArrowColor: `#000`,
                        colorText: `#262626 !important`,
                    },
                    Checkbox: {
                        colorText: `#262626`,
                        colorPrimary: `#EF6E27`,
                        colorPrimaryHover: `#c4581e`,
                    },
                    Select: {
                        fontSize: 18,
                        controlHeight: 45,
                        borderRadius: 10,
                        colorTextPlaceholder: `#000`
                    },
                    Input: {
                        colorTextPlaceholder: `#000`
                    }
                },
            }}
        >
            <LiveChatWidget
                license={AppSettings.livechat.license}
                visibility="minimized"
            />

            <div className={styles.wrapper} data-mobile={device.isMobile}>
                <Outlet />
            </div>
        </ConfigProvider>
    </>
});

export default PublicLayout;