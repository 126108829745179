import { Alert, Breadcrumb, Button, Checkbox, Divider, Drawer, Input, notification, Popconfirm, Row, Space, Table, TablePaginationConfig, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { EyeOutlined, DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import React from "react";
import { User } from "api/model/User/User";
import OfficeSelect from "app/components/OfficeSelect";
import ClientEdit from "./ClientEdit";
import { ClientStore } from "./ClientStore";
import { Client } from "api/model/Clients/Client";
import PracticeSelect from "app/components/PracticeSelect";


const ClientList: React.FC = observer(() => {
	const api = useApi();
	const store = useLocalObservable<ClientStore>(() => new ClientStore(api));
	const [editing, setEditing] = React.useState<Client>(null);

	useEffect(() => {
		store.notificationApi = notification;
	}, [])

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<User> | SorterResult<User>[] | any) => {
		store.setSortingPaging(sorter.columnKey, sorter.order, pagination.current, pagination.pageSize);
	};

	const columns: ColumnsType<Client> = [
		{
			title: 'Last Name',
			key: 'LastName',
			ellipsis: true,
			sorter: true,
			render: (x: Client) => <>
				<a onClick={() => setEditing(x)}>
					{x.lastName} {x.firstName}
				</a>
				<div>SyncVetId: {x.syncVetId || <span className="secondary-text">No sync</span>}</div>
			</>
		},
		{
			title: 'Created Date',
			key: 'CreatedDate',
			sorter: true,
			defaultSortOrder: "descend",
			render: (x: Client) =>
				x.createdDate ?
					<span>{moment(x.createdDate).format("DD MMM yyyy HH:mm:ss")}</span>
					:
					<span className="secondary-text">No data</span>

		},
		{
			title: 'Office',
			render: (x: Client) => {
				return <>
					{x.office?.name || <span className="secondary-text">No office</span>}
				</>
			}
		},

		{
			title: 'Data',
			render: (x: Client) => {
				return <>

					<div>email: {x.email || <span className="secondary-text">No data</span>} </div>
					<div>phone: {x.phone || <span className="secondary-text">No data</span>} </div>
				</>
			}
		},
		{
			title: 'Actions',
			align: "right",
			render: (x: Client) => <Space key={x.id}>
				{x.softDeleted && <Tag color={"red"}>Removed</Tag>}

				<Button icon={<EyeOutlined />} onClick={() => setEditing(x)} />

				{!x.softDeleted ?
					<Popconfirm title="Are you sure to delete?" onConfirm={() => store.delete(x)}>
						<Button danger icon={<DeleteOutlined />} />
					</Popconfirm>
					:
					<Button icon={<ReloadOutlined />} onClick={() => store.restore(x)} />
				}
			</Space>
		},
	];

	return <div className="vs_table_wrapper">
		<Space style={{ marginBottom: 10 }} wrap={false}>
			<Input.Search
				style={{ minWidth: 200 }}
				placeholder="Search"
				defaultValue={store.filter.search || ""}
				onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => store.setSearch(e.currentTarget.value)}
			/>

			<PracticeSelect onChange={x => store.setFilter({ practiceId: x })}></PracticeSelect>

			<Button type='primary' onClick={() => setEditing({ id: 0 })}>Add new</Button>
		</Space>

		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
				<Table
					onChange={handleTableChange}
					loading={store.loading}
					rowKey={x => x.id ? x.id : ""}
					columns={columns}
					dataSource={store.pagedData?.data}
					pagination={{
						hideOnSinglePage: true,
						current: store.pagedData?.page,
						total: store.pagedData?.total,
						pageSize: store.pagedData?.pageSize,
						showSizeChanger: true,
						showTotal: (total: number) => `Total: ${total}`
					}}
				/>
		}

		<Drawer destroyOnClose={true} visible={editing != null} onClose={() => setEditing(null)} title={"Client Edit"}>
			<ClientEdit client={editing} onCancel={() => setEditing(null)} onSuccess={() => store.load() && setEditing(null)} />
		</Drawer>
	</div>;
})


export default ClientList;




