import { Alert, Breadcrumb, Button, Checkbox, Divider, Drawer, Input, notification, Popconfirm, Space, Table, TablePaginationConfig, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EyeOutlined, DeleteOutlined, ReloadOutlined, EditOutlined } from "@ant-design/icons";
import React from "react";
import { AppointmentStore } from "./AppointmentStore";
import { AdminAppointmentDto } from "api/routes/Appointments/Appointment/DTO/AdminAppointmentDto";
import AppointmentEdit from "./AppointmentEdit";
import DoctorSelect from "app/components/DoctorSelect";
import ClientSelect from "app/components/ClientSelect";
import OfficeSelect from "app/components/OfficeSelect";
import AppointmentView from "./AppointmentView";
import PracticeSelect from "app/components/PracticeSelect";


const AppointmentList: React.FC = observer(() => {
	const api = useApi();
	const store = useLocalObservable<AppointmentStore>(() => new AppointmentStore(api));
	const [appointmentView, setAppointmentView] = useState<AdminAppointmentDto | null>(null);
	const [editing, setEditing] = React.useState<AdminAppointmentDto>(null);

	useEffect(() => {
		store.notificationApi = notification;
	}, [])

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<AdminAppointmentDto> | SorterResult<AdminAppointmentDto>[] | any) => {

		store.setSortingPaging(sorter.columnKey, sorter.order, pagination.current, pagination.pageSize);

	};

	const handleView = (appointment: AdminAppointmentDto) => {
		setAppointmentView(appointment);
	}

	const columns: ColumnsType<AdminAppointmentDto> = [
		{
			title: 'Date',
			key: 'Date',
			ellipsis: true,
			sorter: true,
			defaultSortOrder: "descend",
			render: (x: AdminAppointmentDto) => {
				return <>
					<a type="link" style={{ fontSize: 16 }}
						onClick={() => x.syncVetId ? setAppointmentView(x) : setEditing(x)}>
						{moment(x.date).utc().format("LLL")}
					</a>
					<div>SyncVetId: {x.syncVetId || <span className="secondary-text">No sync</span>}</div>
					{x.manualCreated && <Tag color={"green"}>Manually created</Tag>}
				</>
			}
		},
		{
			title: 'Doctor',
			key: 'Doctor',
			ellipsis: true,
			sorter: true,
			render: (x: AdminAppointmentDto) => <>
				<span style={{ fontSize: 16 }}>
					{x.doctorName}
				</span>
			</>
		},
		{
			title: 'Client',
			key: 'Client',
			ellipsis: true,
			sorter: true,
			render: (x: AdminAppointmentDto) => <>
				<span style={{ fontSize: 16 }}>
					{x.clientName}
				</span>
			</>
		},

		{
			title: 'Patient',
			key: 'Patient',
			ellipsis: true,
			sorter: true,
			render: (x: AdminAppointmentDto) => <>
				<span style={{ fontSize: 16 }}>
					{x.patientName} <br />
					{x.patientBreedCode}
				</span>
			</>
		},
		{
			title: 'Feedback',
			render: (x: AdminAppointmentDto) => {
				if (!x.feedback) return <span className="secondary-text">No feedback</span>
				return <>
					<Link to={`/recordings/${x.feedback.id}`}>{moment(x.feedback?.createdDate).format("LLL")}</Link>
					<div>
						<Tag>{x.feedback.status}</Tag>
					</div>
				</>
			}
		},
		{
			title: 'Status',
			render: (x: AdminAppointmentDto) => {
				return <>
					<Tag>{x.status}</Tag>
				</>
			}
		},
		{
			title: 'Actions',
			align: "right",
			render: (x: AdminAppointmentDto) => <Space key={x.id}>
				{x.softDeleted && <Tag color={"red"}>Removed</Tag>}

				{x.syncVetId ?
					<Button icon={<EyeOutlined />} onClick={() => setAppointmentView(x)} />
					:
					<Button icon={<EditOutlined />} onClick={() => setEditing(x)} />
				}


				{!x.softDeleted ?
					<Popconfirm title="Are you sure to delete?" onConfirm={() => store.delete(x)}>
						<Button danger icon={<DeleteOutlined />} />
					</Popconfirm>
					:
					<Button icon={<ReloadOutlined />} onClick={() => store.restore(x)} />
				}
			</Space>
		},
	];

	return <div className="vs_table_wrapper">
		<Space style={{ marginBottom: 10 }} wrap={false}>
			<Button type={"primary"} onClick={() => setEditing({ id: 0 } as AdminAppointmentDto)}>Add new</Button>

			<Input.Search
				style={{ minWidth: 200 }}
				placeholder="Search"
				defaultValue={store.filter.search || ""}
				onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => store.setSearch(e.currentTarget.value)}
			/>

			<PracticeSelect onChange={x => store.setFilter({ practiceId: x })}></PracticeSelect>

			<Checkbox
				indeterminate={store.filter.isCompleted == null}
				checked={store.filter.isCompleted === true} onClick={() => store.toggleIsCompleted()}
			>
				Completed
			</Checkbox>

			<Checkbox
				indeterminate={store.filter.isRemoved == null}
				checked={store.filter.isRemoved === true} onClick={() => store.toggleIsRemoved()}
			>
				Removed
			</Checkbox>

			<DoctorSelect value={store.filter.doctorId} onChange={(value) => store.setFilter({ doctorId: value })} />
			<OfficeSelect value={store.filter.officeId} onChange={(value) => store.setFilter({ officeId: value })} />
			<ClientSelect value={store.filter.clientId} onChange={(value) => store.setFilter({ clientId: value })} />
		</Space>

		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
				<Table
					onChange={handleTableChange}
					loading={store.loading}
					rowKey={x => x.id ? x.id : ""}
					columns={columns}
					dataSource={store.pagedData?.data}
					pagination={{
						hideOnSinglePage: true,
						current: store.pagedData?.page,
						total: store.pagedData?.total,
						pageSize: store.pagedData?.pageSize,
						showSizeChanger: true,
						showTotal: (total: number) => `Total: ${total}`
					}}
				/>
		}

		<Drawer visible={appointmentView != null}
			onClose={() => setAppointmentView(null)} width={500}
			title="Appointment Information">
			<AppointmentView appointment={appointmentView} onLangChange={l => console.log(l)} />
		</Drawer>

		<Drawer visible={editing != null}
			onClose={() => setEditing(null)} width={500}
			title="Appointment Edit">
			<AppointmentEdit
				appointment={editing}
				onCancel={() => setEditing(null)}
				onSuccess={() => {
					setEditing(null);
					store.load();
				}}

			/>
		</Drawer>
	</div>;
})


export default AppointmentList;




