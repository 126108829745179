import React, { useCallback, useContext, useEffect, useState } from 'react';
import { List, Button, Drawer, Tag, Image, Skeleton } from 'antd';
import styles from './AppointmentList.module.scss';
import moment from 'moment';
import { UndoOutlined } from '@ant-design/icons';
import useDrawer from 'misc/hooks/useDrawer';
import AppointmentForm from '../AppointmentForm/AppointmentForm';
import { AppointmentCreateDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentCreateDto';
import { AppointmentClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentClientDto';
import { AppointmentListStore } from './AppointmentListStore';
import { observer } from 'mobx-react';
import { getAppointmentFeedbackStatusLabel } from 'api/vs-recording/model/Appointments/enums/AppointmentFeedbackStatus';
import { MainContext } from 'misc/context/MainContext';
import { AudioFilled } from '@ant-design/icons';
import placeholderLogo from 'assets/v2/mini-logo.svg';
import FadeIn from 'app/components/FadeIn/FadeIn';
import useCurrentUser from 'misc/hooks/useCurrentUser';

type AppointmentListProps = {
    store: AppointmentListStore;
    currentDate: moment.Moment;
    onAppointmentClick: (appointment: AppointmentClientDto) => void;
}

const AppointmentList: React.FC<AppointmentListProps> = observer(({ store, currentDate, onAppointmentClick }) => {
    const appointmentDrawer = useDrawer<{ appointment?: AppointmentCreateDto, redirect: boolean }>();
    const [appointments, setAppointments] = useState<AppointmentClientDto[]>([]);

    const ctx = useContext(MainContext);

    useEffect(() => {
        ctx.currentUser.init(ctx.api);
    }, [appointments]);

    useEffect(() => {
        setAppointments(store.pagedData?.data);
    }, [store.pagedData?.data]);

    const createAppointment = useCallback(async (a: AppointmentCreateDto) => {
        const res = await store.createAppointment(a);
        appointmentDrawer.close();
        if (appointmentDrawer.data?.redirect == true) onAppointmentClick(res as AppointmentClientDto);
    }, [appointmentDrawer.data?.redirect]);

    const refresh = useCallback(async () => {
        await store.load()
    }, []);

    return <FadeIn>
        <div className={styles.wrapper}>
            <div className={styles.recBtn} onClick={() => appointmentDrawer.open()}>
                <div className={styles.recIconWrapper}>
                    <AudioFilled className={styles.recIcon} />
                </div>
                <span className={styles.recText}>Start new recording</span>
            </div>
            <Button type='link' onClick={() => appointmentDrawer.open({ redirect: false })}>or Create Appointment(s)</Button>
            <div className={styles.appointmentListContainer}>
                <div className='flex align-center justify-between'>
                    <Button onClick={refresh} loading={store.loading} className={styles.refreshButton} icon={<UndoOutlined />} style={{ marginLeft: 'auto' }}>
                        Refresh
                    </Button>
                </div>
                {store.loading == true
                    ? <FadeIn delay={300}><Skeleton active /></FadeIn>
                    : <div className={styles.appointmentList}>
                        {appointments?.length > 0
                            ? <FadeIn>
                                <List
                                    dataSource={appointments}
                                    renderItem={appointment =>
                                        <List.Item key={appointment.id} className='p-0 mb-10' style={{ borderBlockEnd: 'none' }}>
                                            <OneAppointment appointment={appointment} onAppointmentClick={() => onAppointmentClick(appointment)} />
                                        </List.Item>}
                                /></FadeIn>
                            : <FadeIn>
                                <div className='flex flex-col align-center justify-center fs-22 fw-500 color-white'>
                                    <span className='color-black'>No appointments found</span>
                                    <span className='fs-16 color-grey'>To start recording you need to have an appointment.</span>
                                </div>
                            </FadeIn>}
                    </div>}

            </div>

            <Drawer open={appointmentDrawer.visible}
                destroyOnClose
                title='New Appointment'
                onClose={appointmentDrawer.close}
                width={600}>
                <AppointmentForm appointment={appointmentDrawer.data?.appointment}
                    currentDate={currentDate}
                    onCancel={appointmentDrawer.close}
                    onDone={(a) => createAppointment(a)} />
            </Drawer>
        </div>
    </FadeIn>;
});


type OneAppointmentProps = {
    appointment: AppointmentClientDto;
    onAppointmentClick: () => void;
    onDone?: () => void;
}

export const OneAppointment: React.FC<OneAppointmentProps> = ({ appointment, onAppointmentClick, onDone }) => {
    const user = useCurrentUser();
    const statusData = getAppointmentFeedbackStatusLabel(appointment.feedback?.status);

    return <div className={styles.appointment_wrapper} onClick={() => onAppointmentClick()}>
        <div className='flex gap-5 align-center'>
            <div className={styles.patientImage}>
                <Image src={placeholderLogo} preview={false} />
            </div>
            <div className='flex flex-col'>
                <span className={styles.appointment_patientName}>
                    {appointment.patientName}
                </span>
                <span className={styles.appointment_clientName}>
                    {appointment.clientName}
                </span>
            </div>
        </div>
        <span className='fs-16 flex flex-col align-end gap-3'>
            <div>{moment(appointment.date).format('lll')}</div>
            <Tag style={{
                color: statusData.colors.color, background: statusData.colors.background, borderColor: statusData.colors.border
            }} className='fs-14'>
                {statusData.label}
            </Tag>
        </span>
    </div >;
};

export default AppointmentList;