import { Button, Input, InputRef, Typography, notification } from 'antd';
import { AppointmentFeedbackAudio } from 'api/model/Audio/AppointmentFeedbackAudio';
import { AppointmentFeedbackNote } from 'api/vs-recording/model/Appointments/AppointmentFeedbackNote';
import { TemplateFieldType } from 'api/vs-recording/model/Template/TemplateField';
import { AppointmentFeedbackClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto';
import FadeIn from 'app/components/FadeIn/FadeIn';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useContext, useMemo, useRef } from 'react';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import styles from './SubmitFeedback/SubmitFeedback.module.scss';

const { Text, Title } = Typography;

type EditFeedbackProps = {
    feedback: AppointmentFeedbackClientDto;
    onDoneOrCancel: () => void;
};

const EditFeedback: React.FC<EditFeedbackProps> = observer(({ feedback, onDoneOrCancel }) => {
    const appointmentContext = useContext(AppointmentStoreContext);

    const cancelEditing = async () => {
        try {
            await appointmentContext?.feedbackCancelEdit(feedback);
            onDoneOrCancel();
        } catch (e: any) {
            notification.error({ message: e.message, duration: 2 });
        }
    };

    const saveEditing = async () => {
        try {
            await appointmentContext?.feedbackSaveEdit(feedback);
            onDoneOrCancel();
        } catch (e: any) {
            notification.error({ message: e.message, duration: 2 });
        }
    };

    const noteList = (notes: AppointmentFeedbackNote[]) => notes?.map((note, index) => {
        const defaultName = "Note #" + (index + 1);
        const noteName = note.name && note.name.length > 0 ? note.name : note.templateFieldId ? null : defaultName;

        return <OneNoteEdit
            type={note.type}
            name={noteName}
            placeholder={note.templateField?.defaultValue}
            key={note.id + "|" + index + "|" + note.createdDate}
            value={note.text || ""}
            onChange={(t) => runInAction(() => {
                note.needToSave = true;
                note.text = t;
            })}
            audios={note.appointmentFeedbackAudio || []}
        />;
    });

    const templateFieldNotes = useMemo(() => feedback?.notes
        .filter(x => x.templateFieldId != null)
        .sort((a, b) => (a.position || 0) - (b.position || 0)), [feedback?.notes]);

    const customNotes = useMemo(() => feedback?.notes
        .filter(x => x.templateFieldId == null && x.isAmbient == false)
        .sort((a, b) => a.createdDate == null || b.createdDate == null ? 1 :
            new Date(a.createdDate).getTime() > new Date(b.createdDate).getTime() ? 1 : -1), [feedback?.notes]);


    return <FadeIn>
        <div>
            <Title level={4} className='color-black'>Edit recording</Title>
            <div className={styles.summmaryScroll}>
                {noteList(templateFieldNotes)}
                {customNotes?.length > 0 && (
                    <>
                        <div className='mt-10 mb-10'>
                            <Text className='color-black fs-16 fw-500'>CUSTOM NOTES</Text>
                        </div>
                        {noteList(customNotes)}
                    </>
                )}
            </div>
            <footer className={styles.footer}>
                <Button size='large' onClick={cancelEditing} loading={appointmentContext!.loading == true}>
                    Cancel
                </Button>
                <Button size='large' onClick={saveEditing} loading={appointmentContext!.loading == true}>
                    Save
                </Button>
            </footer>
        </div>
    </FadeIn>;
});

export default EditFeedback;

const OneNoteEdit: React.FC<{
    type?: TemplateFieldType,
    onChange: (t: string) => void,
    value: string,
    audios: AppointmentFeedbackAudio[],
    placeholder?: string,
    name: string | null
}> = observer(({ onChange, value, name, type }) => {
    const inputRef = useRef<InputRef>(null);

    const rowsInValue = value.split('\n').length;

    if (type == TemplateFieldType.Separator)
        return <div className='mb-5 mt-10'>
            <Text className='fs-16 color-black fw-500'>{name}</Text>
        </div>;

    return <div className='mt-10 mb-20'>
        {name && <div>
            <Text className='fs-14 color-black fw-500'>{name}</Text>
        </div>}
        <div>
            <Input.TextArea
                ref={inputRef}
                rows={rowsInValue ?? 2}
                onChange={(e) => onChange(e.target.value)}
                placeholder="Enter text here"
                value={value}
            />
        </div>
    </div>;
});
