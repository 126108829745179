
const COLORS = {
	mainBG: "#ffffff",
	lightMainBg: "#ffffff",
	black: "#262626",
	tintColorLight: '#2f95dc',
	tintColorDark: '#fff',
	modalBg: "#F8F8F8",
	darkGrey: "#595959",

	white: '#fff',
	textWhite: "fff",
	buttonBg: "#EF6E27",
	red: "#F5222D",
	redLighter: "#FFF1F0",
	redDarker: "#AD4836",
	blue: "#0958d9",
	lightBlue: "#91caff",
	textLink: "#1890FF",

	purple: "#814341",
	dirtyGreen: "#6A5E3E",
	green: "#52C41A",
	orange: "#EF6E27",
	orangeDarker: "#A38462",
	greyGreen: "#686B5B",
	orangeLight: "#FFF2E8",

	disabledBG: "#CEC4B8",
	grey: "#777777",
	lightGrey: '#d9d9d9',
	secondaryText: "#B28A75",
	placeholderColor: "#BFBFBF",

	//breed colors
	patientDog: "#D55F4A",
	patientCat: "#814341",
	patientLizard: "#6A5E3E",
	patientBird: "#C28D4B",
	patientSnake: "#686B5B",

	// status text colors
	statusDraft: "#595959",
	statusSubmitted: "#2F54EB",
	statusCompleted: "#52C41A",
	statusApproved: "#52C41A",
	statusProcessing: "#0958d9",
	statusReadyToProcess: "#FA8C16",
	statusEditing: "#722ED1",
	statusProcessed: "#FA8C16",
	statusPreprocessingDone: "#FA8C16",
	statusExported: "#13C2C2",

	// status background colors
	statusDraftBg: "#F5F5F5",
	statusSubmittedBg: "#F0F5FF",
	statusCompletedBg: "#F6FFED",
	statusApprovedBg: "#F6FFED",
	statusProcessingBg: "#FFF7E6",
	statusReadyToProcessBg: "#FFF7E6",
	statusEditingBg: "#F9F0FF",
	statusProcessedBg: "#FFF7E6",
	statusPreprocessingDoneBg: "#FFF7E6",
	statusExportedBg: "#E6FFFB",

	// stattus border colors
	statusDraftBorder: "#BFBFBF",
	statusSubmittedBorder: "#ADC6FF",
	statusCompletedBorder: "#B7EB8F",
	statusApprovedBorder: "#B7EB8F",
	statusProcessingBorder: "#FFD591",
	statusReadyToProcessBorder: "#FFD591",
	statusEditingBorder: "#D3ADF7",
	statusProcessedBorder: "#FFD591",
	statusPreprocessingDoneBorder: "#FFD591",
	statusExportedBorder: "#87E8DE",

	buttonBgHover: "#da6323",
	ligthRed: "#ff2600",
}

export { COLORS };