import React from "react";
import { Alert, Breadcrumb, Button, Divider, Drawer, Input, notification, Popconfirm, Select, Space, Table, TablePaginationConfig, Tag, TimeRangePickerProps, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import useApi from "misc/hooks/useApi";
import { observer, useLocalObservable } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import { Link, useParams, useRoutes, useSearchParams } from "react-router-dom";
import { DeleteOutlined, ReloadOutlined, ApiOutlined, StopOutlined } from "@ant-design/icons";
import { Appointment } from "api/model/Appointments/Appointment";
import { Practice } from "api/model/SyncVet/Practice";
import { SyncVetPracticeStatus, SyncVetPracticeStatusList } from "api/model/SyncVet/SyncVetPracticeStatus";
import useCurrentUser from "misc/hooks/useCurrentUser";
import { UserRoleEnum } from "api/model/User/UserRole";
import { UserReportStore } from "./UserReportStore";
import { UserReport } from "api/model/Analytics/UserReport";
import DatePicker from "app/components/DatePicker";
import AccountSelect from "app/components/AccountSelect";


const UserReportList: React.FC = observer(() => {
	const api = useApi();
	const store = useLocalObservable<UserReportStore>(() => new UserReportStore(api));

	useEffect(() => {
		store.notificationApi = notification;
	}, [])

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<Appointment> | SorterResult<Appointment>[] | any) => {
		store.setSortingPaging(sorter.columnKey, sorter.order, pagination.current, pagination.pageSize);
	};

	const columns: ColumnsType<UserReport> = [
		{
			title: 'Created Date',
			key: 'CreatedDate',
			ellipsis: true,
			sorter: true,
			defaultSortOrder: "descend",
			render: (x: UserReport) => <>
				{moment(x.createdDate).format("LLL")}
			</>
		},
		{
			title: 'Email',
			key: 'email',
			ellipsis: true,
			render: (x: UserReport) => <>
				<div>
					{x.fullName}
				</div>
				<div className={'secondary-text'}>
					{x.email}
				</div>

			</>
		},
		{
			title: 'Doctor',
			key: 'doctor',
			ellipsis: true,
			render: (x: UserReport) => <>
				{!x.doctorName ?
					<span className="secondary-text">No doctor</span>
					:
					<span >
						{x.doctorName}
					</span>
				}
			</>
		},
		{
			title: 'Account',
			key: 'account',
			ellipsis: true,
			render: (x: UserReport) => <>
				{!x.accountName ?
					<span className="secondary-text">No doctor</span>
					:
					<>
						<div>
							{x.accountName}
						</div>
						{x.accountCreatedDate &&
							<div className={'secondary-text'}>
								{moment(x.accountCreatedDate).format("LLL")}
							</div>
						}
					</>

				}
			</>
		},
		{
			title: 'Rec Count',
			render: (x: UserReport) => {
				return <span>{x.recordingCountForPeriod ?? 0}</span>
			}
		},
		{
			title: 'Rec First',
			render: (x: UserReport) => {
				return x.firstRecordingDate ?
					<>
						<div>{moment(x.firstRecordingDate).format("LLL")}</div>
						<div className="secondary-text">{moment(x.firstRecordingDate).fromNow()}</div>
					</>
					:
					<span className="secondary-text">No rec</span>
			}
		},
		{
			title: 'Rec Last',
			render: (x: UserReport) => {
				return x.lastRecordingDate ?
					<>
						<div>{moment(x.lastRecordingDate).format("LLL")}</div>
						<div className="secondary-text">{moment(x.lastRecordingDate).fromNow()}</div>
					</>
					:
					<span className="secondary-text">No rec</span>

			}
		}

	];

	return <div className="vs_table_wrapper">
		<Space style={{ marginBottom: 10 }} wrap={false}>
			<Input.Search
				style={{ minWidth: 200 }}
				placeholder="Search"
				defaultValue={store.filter.search || ""}
				onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => store.setSearch(e.currentTarget.value)}
			/>

			<DatePicker.RangePicker
				presets={[
					{ label: 'This month', value: [moment().startOf('month'), moment().endOf('month')] },
					{ label: 'Last month', value: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')] },
					{ label: 'This quarter', value: [moment().startOf('quarter'), moment().endOf('quarter')] },
					{ label: 'Last quarter', value: [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')] }

				]}
				value={[moment(store.filter.userCreatedFrom), moment(store.filter.userCreatedTo)]}
				showTime={false}
				onChange={(dates, dateStrings) => {
					store.setFilter({ userCreatedFrom: dates[0].toDate(), userCreatedTo: dates[1].toDate() });
				}}
			/>

			<AccountSelect
				value={store.filter.idAccount}
				onChange={(value) => store.setFilter({ idAccount: value })}
			/>

		</Space>

		{
			store.error ?
				<Alert message={store.error} type="error" />
				:
				store.pagedData?.data?.length == 0 ?
					<Alert message="Do data to display" type="info" />
					:
					<Table
						onChange={handleTableChange}
						loading={store.loading}
						rowKey={x => x.email}
						columns={columns}
						dataSource={store.pagedData?.data}
						pagination={{
							hideOnSinglePage: false,
							current: store.pagedData?.page,
							total: store.pagedData?.total,
							pageSize: store.pagedData?.pageSize,
							showSizeChanger: true,
							pageSizeOptions: ['50', '100', '500', '1000'],
							showTotal: (total: number) => `Total: ${total}`
						}}
					/>
		}
	</div>;
})


export default UserReportList;
