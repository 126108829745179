import React, { useState, useEffect, cloneElement, ReactElement } from 'react';

interface FadeInProps {
    children: ReactElement;
    duration?: number;
    delay?: number;
}

const FadeIn: React.FC<FadeInProps> = ({ children, duration = 300, delay = 0 }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, delay);

        return () => clearTimeout(timer);
    }, [delay]);

    const animationStyle = {
        opacity: isVisible ? 1 : 0,
        transition: `opacity ${duration}ms ease-in-out`,
    };

    return cloneElement(children, {
        style: {
            ...animationStyle,
            ...children.props.style, // Сохраняем исходные стили ребенка
            // Добавляем анимацию
        },
    });
};

export default FadeIn;
