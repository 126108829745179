import { AppointmentStatus, AppointmentStatusList } from "api/model/Appointments/enums/AppointmentStatus";
import { Alert, Input, Button, Form, Divider, notification, Row, Select, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import ClientSelect from "app/components/ClientSelect";
import DoctorSelect from "app/components/DoctorSelect";
import OfficeSelect from "app/components/OfficeSelect";
import PatientSelect from "app/components/PatientSelect";
import useApi from "misc/hooks/useApi";
import useCurrentUser from "misc/hooks/useCurrentUser";
import moment from "moment";
import React, { useState } from "react";
import { AdminAppointmentDto } from "api/routes/Appointments/Appointment/DTO/AdminAppointmentDto";
import { AdminAppointmentUpdateRequest } from "api/routes/Appointments/Appointment/DTO/AdminAppointmentUpdateRequest";
import DatePicker from "app/components/DatePicker";


const AppointmentEdit: React.FC<{ appointment: AdminAppointmentDto, onSuccess: () => void, onCancel: () => void }> = ({ appointment, onSuccess, onCancel }) => {
	const api = useApi();
	const currentUser = useCurrentUser();
	const [form] = useForm();
	const [state, setState] = useState<{ error: string | null, loading: boolean }>({ error: null, loading: false });


	let initialData: any = appointment;
	if (appointment != null) {
		initialData = {
			...appointment,
			date: appointment.date ? moment(appointment.date).utc() : moment().utc()
		} || {}
	}


	const submit = async (values: AdminAppointmentUpdateRequest) => {
		setState({ ...state, loading: true, error: "" })

		try {
			values.id = appointment.id;
			values.date = moment(values.date).toDate();

			const res = await api.adminAppointment.updateCreate(values);
			if (res.success == true) {
				notification.success({ message: "Saved" })
				setState({ ...state, error: null, loading: false })
				if (onSuccess) onSuccess()
			} else {
				setState({ ...state, loading: false, error: res.error || "Error occured" })
			}

		} catch (e: any) {
			setState({ ...state, error: e.message, loading: false })
		}
	}

	if (!appointment) return null;

	return <Form form={form} onFinish={submit} layout="vertical" initialValues={initialData} >
		{state.error && <Alert style={{ marginBottom: 10 }} type="warning" message={state.error} />}

		<Form.Item label={"Date"} name="date">
			{/* format={"DD MMM YY h:mm a"} */}
			<DatePicker allowClear showTime use12Hours showSecond={false} />
		</Form.Item>

		<Form.Item label={"Reason"} name="reason">
			<Input type="text" />
		</Form.Item>

		<Form.Item label={"Office"} name="officeId">
			<OfficeSelect
				value={appointment.officeId ? appointment.officeId : null}
				onChange={(x: any) => form.setFieldsValue({ officeId: x })} />
		</Form.Item>

		<Form.Item label={"Client"} name="clientId">
			<ClientSelect
				value={appointment.clientId ? appointment.clientId : null}
				onChange={(x: any) => form.setFieldsValue({ clientId: x })} />
		</Form.Item>

		<Form.Item label={"Patient"} name="patientId">
			<PatientSelect
				clientId={appointment.patientId}
				value={appointment.patientId ? appointment.clientId : null}
				onChange={(x: any) => form.setFieldsValue({ patientId: x })} />
		</Form.Item>

		<Form.Item label={"Doctor"} name="doctorId">
			<DoctorSelect
				value={appointment.doctorId ? appointment.doctorId : null}
				onChange={(x: any) => form.setFieldsValue({ doctorId: x })} />
		</Form.Item>

		<Divider />

		<Form.Item label={"Status"} name="status">
			<Select>
				{AppointmentStatusList.map(x => <Select.Option key={x.value} value={x.value}>{x.label}</Select.Option>)}
			</Select>
		</Form.Item>


		<Row justify="space-between">
			<Button onClick={onCancel} loading={state.loading}>{"Cancel"}</Button>
			<Button type="primary" htmlType="submit" loading={state.loading}>{"Save"}</Button>
		</Row>
	</Form>
}

export default AppointmentEdit;
