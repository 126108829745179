import { DeleteOutlined, AudioFilled, SaveOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Modal, Popconfirm, Typography } from 'antd';
import { AppointmentFeedbackAudio } from 'api/vs-recording/model/Audio/AppointmentFeedbackAudio';
import { TemplateFieldType } from 'api/vs-recording/model/Template/TemplateField';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import AudioPlayer from '../../../AudioPlayer/AudioPlayer';
import AudioRecorder from '../../../AudioRecorder/AudioRecorder';
import styles from '../../../../Recording.module.scss';
import { AppointmentFeedbackNote } from 'api/vs-recording/model/Appointments/AppointmentFeedbackNote';
import { COLORS } from 'vs-recording/styles/Colors';
import submitStyles from '../../SubmitFeedback.module.scss';

const { TextArea } = Input;
const { Title } = Typography;

const OneNote: React.FC<{
    currentNote: AppointmentFeedbackNote,
    type?: TemplateFieldType,
    // onRecordedAudio?: (uri: string, duration: number) => void,
    onDeleteAudio: (audio: AppointmentFeedbackAudio) => void,
    // onUpdateRecording?: (uri: string, duration: number) => void,
    onChange: (t: string) => void,
    removable: boolean,
    value: string,
    onDelete: () => void,
    loading?: boolean,
    saveNote: () => void,
    audios: AppointmentFeedbackAudio[],
    name: string | null
}> = observer(({ currentNote, removable, onChange, value, onDelete, saveNote, loading, audios, onDeleteAudio, name, type }) => {
    const inputRef = useRef<InputRef>(null)
    const [isOpen, setIsOpen] = useState(false);
    const appointmentContext = useContext(AppointmentStoreContext);
    const recorderRef = useRef<any>(null);

    const toggleOpen = async () => {
        setIsOpen(previousState => !previousState);
    };

    const modalInnerDisabled = useMemo(() => {
        return appointmentContext?.isRecording;
    }, []);

    const audiosScrollRef = useRef<HTMLDivElement>(null);

    const onRecordedAudio = async (uri: string, duration: number) => {
        await appointmentContext!.addOrUpdateAudioToNote(uri, duration, currentNote);
    }

    const onUpdateRecording = async (uri: string, duration: number) => {
        await appointmentContext!.addOrUpdateAudioToNote(uri, duration, currentNote, true);
    }

    const deleteNote = () => {
        toggleOpen();
        onDelete();
    };


    const save = async () => {
        await recorderRef.current?.stopRecording();
        saveNote();
        toggleOpen();
    };

    const title = (modal?: boolean) => {
        if (name == null) return null;
        if (modal) return <Title level={5} style={{ color: COLORS.black, margin: 0 }}>Record notes ({name})</Title>
        return <Title level={5} style={{ color: COLORS.black, margin: 0 }} > {name}</ Title>
    };

    if (type == TemplateFieldType.Separator) {
        return <div>
            <Title level={4} className='color-white'>{name}</Title>
        </div>;
    };

    return <div>
        <div className={styles.oneNote}>
            {title()}
            {audios.map((x, i) => (
                <div key={`${x.uri} | ${i}`} className='mt-10'>
                    <AudioPlayer audio={x} onDelete={() => onDeleteAudio(x)} showDelete={true} />
                </div>
            ))}
            {value.length > 0 && <Typography.Text style={{ color: COLORS.black, fontSize: 14 }}>{value}</Typography.Text>}
            <div className={styles.recBtnMini} onClick={toggleOpen}>
                <div className={styles.iconWrapper}>
                    <AudioFilled className={styles.icon} />
                </div>
                <span className={styles.recText}>
                    Record notes
                </span>
            </div>
        </div>

        <Modal open={isOpen} onCancel={toggleOpen}
            footer={null} width={700}
            title={title(true)}
            style={{ borderRadius: 25, paddingBottom: 0 }}>
            <div className={submitStyles.oneNoteModalContent}>
                <div ref={audiosScrollRef}>
                    {audios.length > 0 && <div className={submitStyles.modalAudiosScroll}>
                        {audios.filter(x => x && !x.isInProgress && !x.isSaving).map((x, i) => (
                            <div key={`${x.uri} | ${i}`} style={{ marginBottom: 5 }} >
                                <AudioPlayer audio={x} onDelete={() => onDeleteAudio(x)} showDelete={true} showContols={false} />
                            </div>
                        ))}
                    </div>}
                    <span>Enter text here</span>
                    <TextArea
                        ref={inputRef}
                        disabled={modalInnerDisabled}
                        style={{ marginBottom: 10 }}
                        rows={6}
                        onChange={(e) => onChange(e.target.value)}
                        placeholder="No text"
                        value={value}
                    />

                </div>
                <AudioRecorder
                    noteId={currentNote.id}
                    feedbackId={appointmentContext?.appointment?.feedback?.id}
                    onRecorded={onRecordedAudio}
                    recorderRef={recorderRef}
                    onError={appointmentContext?.setError}
                    onUpdateRecording={onUpdateRecording}
                    mode='modal'
                />
                <div className={submitStyles.footer}>
                    {removable &&
                        <Popconfirm okType='default' disabled={modalInnerDisabled} title="Are you sure you want to delete this note?" onConfirm={deleteNote}>
                            <Button disabled={modalInnerDisabled} icon={<DeleteOutlined />} />
                        </Popconfirm>}
                    <Button disabled={modalInnerDisabled} icon={<SaveOutlined />} style={{ marginLeft: 'auto' }} loading={loading} onClick={save}>Save</Button>
                </div>
            </div>
        </Modal>
    </div>;
});

export default OneNote;
