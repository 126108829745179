import { observer } from 'mobx-react';
import React, { useContext, useEffect, useRef } from 'react';
import { Typography } from 'antd';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import { AppointmentFeedbackClientDto } from 'api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto';
import { AppointmentFeedbackAudio } from 'api/vs-recording/model/Audio/AppointmentFeedbackAudio';
import AudioRecorder from '../AudioRecorder/AudioRecorder';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import styles from './SubmitFeedback.module.scss';

const { Text } = Typography;

type SubmitFeedbackAmbientProps = {
	feedback: AppointmentFeedbackClientDto;
	onRecordingStart?: () => void;
	onRecordingStopped?: () => void;
};

const SubmitFeedbackAmbient: React.FC<SubmitFeedbackAmbientProps> = observer(({ feedback, onRecordingStart, onRecordingStopped }) => {
	const appointmentContext = useContext(AppointmentStoreContext);
	const ambientNote = feedback?.notes?.find(x => x.isAmbient);
	const audiosScrollRef = useRef<HTMLDivElement>(null);
	const audios = ambientNote?.appointmentFeedbackAudio || [];

	useEffect(() => {
		if (audiosScrollRef.current) {
			audiosScrollRef.current.scrollTop = audiosScrollRef.current.scrollHeight;
		}
	}, [audios.length]);

	if (ambientNote == null) {
		return <div>
			<Text className="title">Error occurred, try to refresh</Text>
		</div>
	}

	const onRecordedAudio = async (uri: string, duration: number) => {
		await appointmentContext!.addOrUpdateAudioToNote(uri, duration, ambientNote);
	}

	const onUpdateRecording = async (uri: string, duration: number) => {
		await appointmentContext!.addOrUpdateAudioToNote(uri, duration, ambientNote, true);
	}

	const onDeleteAudio = async (audio: AppointmentFeedbackAudio) => {
		await appointmentContext!.deleteAudioFromNote(audio, ambientNote);
	}

	const onRecordingStarted = () => {
		appointmentContext!.setIsRecording(true);
		if (onRecordingStart) {
			onRecordingStart();
		}
	}

	const onRecordingStop = () => {
		appointmentContext!.setIsRecording(false);
		if (onRecordingStopped) {
			onRecordingStopped();
		}
	}

	return <div className={styles.wrapper}>
		<div className={styles.ambientAudioListContainer}>
			{audios.length == 0
				? <div className={styles.ambientEmptyState}>
					<p>Make one or multiple recordings during or after the visit. <br /> We will combine all recordings and fill them out according to the selected template</p>
				</div>
				: <div ref={audiosScrollRef} className={styles.ambientAudiosScroll}>
					{audios.map((x, i) => (
						<div key={`${x.uri} | ${i}`} style={{ marginBottom: '10px' }}>
							<AudioPlayer audio={x} onDelete={() => onDeleteAudio(x)} showDelete />
						</div>
					))}
				</div>}
		</div>
		<AudioRecorder noteId={ambientNote.id} feedbackId={feedback?.id} onStartRecording={onRecordingStarted} onStopRecording={onRecordingStop} onRecorded={onRecordedAudio} onUpdateRecording={onUpdateRecording} />
	</div>;
});

export default SubmitFeedbackAmbient;