import React, { useContext, useMemo } from "react";
import { observer } from "mobx-react";
import { Typography } from "antd";
import { AppointmentFeedbackClientDto } from "api/vs-recording/routes/Appointment/DTO/AppointmentFeedbackClientDto";
import { AppointmentFeedbackAudio } from "api/vs-recording/model/Audio/AppointmentFeedbackAudio";
import { TemplateFieldType } from "api/vs-recording/model/Template/TemplateField";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import styles from "../SubmitFeedback/SubmitFeedback.module.scss";
import { COLORS } from "vs-recording/styles/Colors";

const { Text } = Typography;

const Summary: React.FC<{ feedback: AppointmentFeedbackClientDto }> = observer(({ feedback }) => {
	const ambientNotes = useMemo(() => {
		return feedback?.notes
			.filter(x => x.isAmbient)
			.flatMap(note => note.appointmentFeedbackAudio || []);
	}, [feedback?.notes]);

	const customNotes = useMemo(() => {
		return feedback?.notes
			.filter(x => x.templateFieldId == null && x.isAmbient === false)
			.sort((a, b) =>
				a.createdDate == null || b.createdDate == null
					? 1
					: new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
			);
	}, [feedback?.notes]);

	const groupedNotes = useMemo(() => {
		const noteGroups: { group: string; items: any[] }[] = [];
		let currentGroup: { group: string; items: any[] } | null = null;

		feedback?.notes
			.filter(note => !note.isAmbient) // Exclude ambient notes from grouping
			.forEach(note => {
				if (note.type === TemplateFieldType.Separator) {
					if (currentGroup) {
						noteGroups.push(currentGroup);
					}
					currentGroup = { group: note.name || "Unnamed Group", items: [] };
				} else {
					if (currentGroup) {
						currentGroup.items.push(note);
					} else {
						noteGroups.push({ group: "", items: [note] });
					}
				}
			});

		if (currentGroup) {
			noteGroups.push(currentGroup);
		}

		return noteGroups.length > 0 ? noteGroups : [{ group: "", items: feedback?.notes }];
	}, [feedback?.notes]);

	const renderNotes = (notes: any[]) => {
		return notes.map((note, index) => {
			const noteName = note.name;
			return <div key={`${note.id}|${index}`} className="mt-5">
				<OneText
					name={noteName}
					date={note.createdDate}
					value={note.text || ""}
					audios={note.appointmentFeedbackAudio || []}
				/>
			</div>;
		});
	};

	return <div className={styles.summmaryScroll}>
		{/* Ambient Recordings Section */}
		{ambientNotes.length > 0 && <div style={{ background: COLORS.modalBg, borderRadius: 25, padding: 16 }}>
			<Text className="fs-16 fw-500 color-black">Ambient Recordings</Text>
			<div className="mt-10" >
				{ambientNotes.map((audio, index) => (
					<div key={`${audio.uri}|${index}`} className="mb-5">
						<AudioPlayer audio={audio} showDelete={false} />
					</div>
				))}
			</div>
		</div>}

		{/* Grouped Notes Section */}
		{groupedNotes.map((group, groupIndex) => <div key={groupIndex} className={styles.dictationNoteSection} style={{
			marginTop: group.group ? 40 : 24,
		}}>
			{group.group && <Text className={styles.dictationNoteSectionTitle}>{group.group}</Text>}
			{renderNotes(group.items)}
		</div>)}

		{/* Custom Notes Section */}
		{customNotes.length > 0 && <div className={styles.dictationNoteSection}>
			<Text className={styles.dictationNoteSectionTitle}>Custom Notes</Text>
			{renderNotes(customNotes)}
		</div>
		}
	</div>;
});

export default Summary;

type OneTextProps = {
	name?: string | null;
	date?: string | Date | null;
	value: string;
	audios: AppointmentFeedbackAudio[];
};

const OneText: React.FC<OneTextProps> = observer(({ value, date, name, audios }) => {
	const hasLettersRE = new RegExp(/[a-zA-Z]/);
	const hasLetters = value.length !== 0 || hasLettersRE.test(value);

	return <div className={styles.oneNote}>
		{name && <div className="flex justify-between mb-10 fw-700">
			<Text className="fs-16">{name}</Text>
		</div>}
		<div>
			{hasLetters
				? <Text className="color-black">{value}</Text>
				: <Text className="color-grey">No text</Text>}
		</div>
		{audios.length > 0 && <div className="mt-5">
			{audios.map((x, i) => (
				<div key={`${x.uri} | ${i}`} className="mb-5">
					<AudioPlayer audio={x} showDelete={false} />
				</div>
			))}
		</div>}
	</div>;
});
