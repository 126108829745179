import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Popconfirm, Progress, Tag } from 'antd';
import { PlayCircleFilled, PauseCircleFilled, DeleteOutlined, UndoOutlined, RedoOutlined, BackwardFilled, ForwardFilled } from '@ant-design/icons';
import WaveSurfer from 'wavesurfer.js';
import { AppointmentFeedbackAudio } from 'api/vs-recording/model/Audio/AppointmentFeedbackAudio';
import AudioFileStorage from '../../../../stores/AudioFileStorage';
import useApi from 'misc/hooks/useApi';
import { AppointmentStoreContext } from 'vs-recording/stores/appointment/AppointmentStoreContext';
import { observer } from 'mobx-react';
import { COLORS } from 'vs-recording/styles/Colors';
import styles from './AudioPlayer.module.scss';
import { set } from 'lodash';

type PlayState = 'playing' | 'paused' | 'stopped';
type AudioPlayerState = {
    playState: PlayState;
    currentSec: number;
    durationSec: number;
};

type AudioPlayerProps = {
    audio: AppointmentFeedbackAudio;
    onDelete?: () => void;
    showDelete: boolean;
    disabled?: boolean;
    showContols?: boolean;
};

const AudioPlayer: React.FC<AudioPlayerProps> = observer(({ audio, onDelete, showDelete, disabled, showContols = true }) => {
    const api = useApi();
    const [state, setState] = useState<AudioPlayerState>({ playState: 'stopped', currentSec: 0, durationSec: 0 });
    const [error, setError] = useState<string | null>(null);
    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurferRef = useRef<WaveSurfer | null>(null);
    const [loadingId, setLoadingId] = useState<number | null>(null);
    const [progress, setProgress] = useState<number>(0);
    const isSaved = audio.id && (audio.id > -1);
    const appointmentContext = useContext(AppointmentStoreContext);
    const isMounted = useRef(true); // fix error "container not found" when unmounting
    const [playbackRate, setPlaybackRate] = useState<number>(1.0);

    const [controlsVisible, setControlsVisible] = useState<boolean>(false);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
            // cleanUpWaveSurfer(); // fix user abort error
        };
    }, []);

    useEffect(() => {
        if (appointmentContext?.audioUploadingUri) return;
        loadAudio();
    }, [audio.uri]);

    const cleanUpWaveSurfer = () => {
        if (wavesurferRef.current) {
            wavesurferRef.current.unAll();
            wavesurferRef.current.destroy();
            wavesurferRef.current = null;
        }
    };

    const loadAudio = useCallback(async () => {
        setLoadingId(audio.id || Date.now());
        setProgress(0);
        setError(null);
        cleanUpWaveSurfer();

        let url: string = null;
        try {
            if (audio.id && audio.id > -1) {
                url = await api.vsRecording.appointment.getAudioUrl(audio);
            } else {
                const blob = await AudioFileStorage.getBlobByKey(audio.uri);
                url = URL.createObjectURL(blob);
            }
            if (waveformRef.current) {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'blob';

                xhr.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = (event.loaded / event.total) * 100;
                        if (isMounted.current) setProgress(percentComplete);
                    }
                };

                xhr.onload = () => {
                    if (xhr.status === 200 && isMounted.current) {
                        const blob = xhr.response;
                        const objectUrl = URL.createObjectURL(blob);

                        wavesurferRef.current = WaveSurfer.create({
                            container: waveformRef.current!,
                            waveColor: COLORS.grey,
                            progressColor: isSaved ? COLORS.green : COLORS.orange,
                            cursorColor: isSaved ? COLORS.green : COLORS.orange,
                            cursorWidth: 2,
                            barWidth: 2,
                            height: 20,
                            normalize: true,
                        });

                        wavesurferRef.current.load(objectUrl);

                        wavesurferRef.current.on('ready', () => {
                            if (isMounted.current) {
                                const duration = wavesurferRef.current?.getDuration() || 0;
                                setState((prevState) => ({ ...prevState, durationSec: Math.round(duration) }));
                                setTimeout(() => setLoadingId(null), 300); // Сбрасываем loadingId после загрузки
                            }
                        });

                        wavesurferRef.current.on('audioprocess', () => {
                            if (isMounted.current) {
                                const currentTime = wavesurferRef.current?.getCurrentTime() || 0;
                                setState((prevState) => ({ ...prevState, currentSec: Math.round(currentTime) }));
                            }
                        });

                        wavesurferRef.current.on('finish', () => {
                            if (isMounted.current) {
                                setState((prevState) => ({ ...prevState, playState: 'stopped', currentSec: prevState.durationSec }));
                            }
                        });

                        URL.revokeObjectURL(url); // Revoke the original object URL
                    } else setError('Failed to load audio file');
                };

                xhr.onerror = () => {
                    if (isMounted.current) {
                        setError('Failed to load audio file');
                        setLoadingId(null);
                    }
                };

                xhr.send();
            }
        } catch (e) {
            setError('Error uploading audio. Try again');
            setLoadingId(null);
        }
    }, [audio.uri, audio.id]);

    const play = useCallback(() => {
        if (isMounted.current) {
            setControlsVisible(true);
            wavesurferRef.current?.play();
            setState((prevState) => ({ ...prevState, playState: 'playing' }));
        }
    }, []);

    const pause = useCallback(() => {
        wavesurferRef.current?.pause();
        setState((prevState) => ({ ...prevState, playState: 'paused' }));
    }, []);

    const deleteAudio = useCallback(() => {
        if (onDelete && isMounted.current) {
            onDelete();
        }
    }, [onDelete]);

    const getAudioTimeString = useCallback((seconds: number) => {
        const totalSeconds = Math.max(0, Math.floor(seconds));
        const h = Math.floor(totalSeconds / 3600);
        const m = Math.floor((totalSeconds % 3600) / 60);
        const s = totalSeconds % 60;
        return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
    }, []);

    const increasePlaybackRate = useCallback(() => {
        setPlaybackRate((prevRate) => Math.min(prevRate + 0.5, 2.0));
        wavesurferRef.current?.setPlaybackRate(playbackRate + 0.5 <= 2.0 ? playbackRate + 0.5 : 2.0);
    }, [playbackRate]);

    const decreasePlaybackRate = useCallback(() => {
        setPlaybackRate((prevRate) => Math.max(prevRate - 0.5, 0.5));
        wavesurferRef.current?.setPlaybackRate(playbackRate - 0.5 >= 0.5 ? playbackRate - 0.5 : 0.5);
    }, [playbackRate]);

    const skipForward = useCallback(() => {
        const currentTime = wavesurferRef.current?.getCurrentTime() || 0;
        wavesurferRef.current?.seekTo(Math.min((currentTime + 10) / (state.durationSec || 1), 1));
        setState((prevState) => ({
            ...prevState,
            currentSec: Math.min(currentTime + 10, prevState.durationSec),
        }));
    }, [state.durationSec]);

    const skipBackward = useCallback(() => {
        const currentTime = wavesurferRef.current?.getCurrentTime() || 0;
        wavesurferRef.current?.seekTo(Math.max((currentTime - 10) / (state.durationSec || 1), 0));
        setState((prevState) => ({
            ...prevState,
            currentSec: Math.max(currentTime - 10, 0),
        }));
    }, [state.durationSec]);

    useEffect(() => {
        // on audio length over, set playState to stopped
        if (state.playState === 'playing' && state.currentSec >= state.durationSec) {
            setState((prevState) => ({ ...prevState, playState: 'stopped' }));
            setControlsVisible(false);
        }
    }, [state.currentSec, state.durationSec]);

    return <div className='w-100'>
        <div style={{ backgroundColor: COLORS.mainBG, borderRadius: '100px', border: `1px solid ${COLORS.lightGrey}`, padding: '8px' }}
            onClick={() => state.playState == 'stopped' && setControlsVisible(!controlsVisible)}>
            <div className='flex gap-20 align-center ml-10'>
                <div className='flex align-center'>
                    {state.playState === 'paused' || state.playState === 'stopped'
                        ? <Button type='text' disabled={disabled} shape="circle" icon={<PlayCircleFilled className='fs-46' style={{ color: isSaved ? COLORS.green : COLORS.grey }} />} onClick={e => {
                            e.stopPropagation();
                            play();
                        }} />
                        : <Button type='text' disabled={disabled} shape="circle" icon={<PauseCircleFilled className='fs-46' style={{ color: COLORS.grey }} />} onClick={e => {
                            e.stopPropagation();
                            pause();
                        }} />}
                </div>
                <div className='flex flex-col w-100' style={{ height: 50 }}>
                    <Progress percent={progress} showInfo={false} style={{ flex: 1, display: loadingId === null && 'none', height: 40 }} />
                    <div ref={waveformRef} style={{ flex: 1, display: loadingId !== null && 'none' }} onClick={e => e.stopPropagation()}></div>
                    <div className='flex justify-between mt-5'>
                        <div className='flex align-center gap-20'>
                            <span className='fs-12'>{getAudioTimeString(state.currentSec)}</span>
                        </div>
                        <div className='flex align-center gap-10'>
                            <Tag className='fs-12' color={error ? COLORS.red : isSaved ? COLORS.green : COLORS.buttonBg}>
                                {error ? error
                                    : loadingId !== null
                                        ? 'Loading' + (progress ? ` ${progress.toFixed(0)} %` : '')
                                        : isSaved ? 'Uploaded' : 'Saved locally'}
                            </Tag>
                            <span className='fs-12'>{getAudioTimeString(state.durationSec)}</span>
                        </div>
                    </div>
                </div>
                <div>
                    {showDelete && <Popconfirm okType='default' disabled={disabled} title="Are you sure you want to delete this audio?" onConfirm={e => {
                        e.stopPropagation();
                        deleteAudio();
                    }}>
                        <Button disabled={disabled} type='text' className='color-grey' icon={<DeleteOutlined className='fs-24' />} onClick={e => e.stopPropagation()} />
                    </Popconfirm>}
                </div>
            </div>
        </div>
        {showContols && <div className='flex align-center gap-5 color-black justify-center w-100 mt-5 mb-10' style={{ height: controlsVisible ? 40 : 0, opacity: controlsVisible ? 1 : 0, transition: 'height 0.3s, opacity 0.3s' }}>
            <Button className={styles.controlsBtn} onClick={decreasePlaybackRate} disabled={playbackRate <= 0.5}>
                <BackwardFilled />
                Speed
            </Button>
            <span className='fs-14 fw-500'>{playbackRate.toFixed(1)}x</span>
            <Button className={styles.controlsBtn} onClick={increasePlaybackRate} disabled={playbackRate >= 2.0}>
                Speed
                <ForwardFilled />
            </Button>
            <Button className={styles.controlsBtn} onClick={skipBackward}>
                <UndoOutlined />
                10s
            </Button>
            <Button className={styles.controlsBtn} onClick={skipForward}>
                <RedoOutlined />
                10s
            </Button>
        </div>}
    </div>;
});

export default AudioPlayer;
